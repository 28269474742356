import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';

import { Button2 } from '.';
import { chatData } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import Button from '@mui/material/Button';

import i18next, { i18n } from 'i18next';

const Chat = () => {
  const { currentColor } = useStateContext();

  function thai() {
    localStorage.setItem('lang', 'th')
    window.location.reload()
  }

  function english() {
    localStorage.setItem('lang', 'en')
    window.location.reload()
  }

  return (
    <div className="nav-item absolute right-5 md:right-52 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-50">
      <div className="flex justify-between items-center">
        <div className="flex gap-3">
          <p className="font-semibold text-lg dark:text-gray-200">{i18next.t('Language')}</p>
        </div>
        <Button2
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="mt-5 ">
        
        <div className="mt-5">
          <Button variant="contained" onClick={thai}>ไทย</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button variant="contained" onClick={english}>English</Button>
        </div>
      </div>
    </div>
  );
};

export default Chat;
