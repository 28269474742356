import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyB4sFAkBdcDLISFh1Wfn6kFr69256jJpro",
  authDomain: "futuresx-e3834.firebaseapp.com",
  projectId: "futuresx-e3834",
  storageBucket: "futuresx-e3834.appspot.com",
  messagingSenderId: "950131308388",
  appId: "1:950131308388:web:0249c20a7cf0118a2a6c71",
  measurementId: "G-4LHX1BYCL7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
const analytics = getAnalytics(app);

export const auth = getAuth()