import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { Navbar, Footer, Sidebar, ThemeSettings, Payment } from './components';
import { Ecommerce, Orders, Calendar, Employees, Stacked, Pyramid, Customers, Kanban, Line, Area, Bar, Pie, Financial, ColorPicker, ColorMapping, Editor, BinanceFutures, Login, UserManagement, Register, Test, BlankkPage, TestWeb3, FuturesX } from './pages';
import './App.css';

import { useStateContext } from './contexts/ContextProvider';
import { useContext } from 'react';
import { AuthContext } from './contexts/AuthContext';

const App = () => {
  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, currentColor, themeSettings, setThemeSettings } = useStateContext();

  const { currentUser } = useContext(AuthContext)

  const RequireAuth = ({children}) => {
    return currentUser ? children : <Navigate to="/login" />
  }
  const RequireAdmin = ({children}) => {
    const getAdmin = currentUser.email == 'ftx.c1920@gmail.com'
    return getAdmin ? children : <Navigate to="/dashboard" />
  }

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
            <TooltipComponent
              content="Settings"
              position="Top"
            >
              <button
                type="button"
                onClick={() => setThemeSettings(true)}
                style={{ background: currentColor, borderRadius: '50%' }}
                className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <FiSettings />
              </button>

            </TooltipComponent>
          </div>
          {activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}
          <div
            className={
              activeMenu
                ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  '
                : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
            }
          >
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
            <Navbar />
            </div>
            <div>
              {themeSettings && (<ThemeSettings />)}

              <Routes>
                {/* Menu  */}
                <Route path="/" element={(<RequireAuth><Ecommerce /></RequireAuth>)} />
                <Route path="/dashboard" element={(<RequireAuth><Ecommerce /></RequireAuth>)} />
                <Route path="/futuresx" element={(<RequireAuth><FuturesX /></RequireAuth>)} />
                <Route path="/payment" element={(<RequireAuth><Payment /></RequireAuth>)} />

                {/* pages  */}
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/tradeorders" element={<RequireAuth><Orders /></RequireAuth>} />
                <Route path="/userdatabase" element={<RequireAuth><RequireAdmin><Employees /></RequireAdmin></RequireAuth>} />
                <Route path="/customers" element={<RequireAuth><Customers /></RequireAuth>} />
                <Route path="/usermanagement" element={<RequireAuth><RequireAdmin><UserManagement /></RequireAdmin></RequireAuth>} />
                <Route path="/futuresx" element={<RequireAuth><FuturesX /></RequireAuth>} />

                <Route path="/test" element={<Test />} />
                <Route path="/blankpage" element={<BlankkPage />} />
                <Route path="/testweb3" element={<TestWeb3 />} />

                {/* apps  */}
                <Route path="/kanban" element={<RequireAuth><Kanban /></RequireAuth>} />
                <Route path="/editor" element={<RequireAuth><Editor /></RequireAuth>} />
                <Route path="/calendar" element={<RequireAuth><Calendar /></RequireAuth>} />
                <Route path="/color-picker" element={<RequireAuth><ColorPicker /></RequireAuth>} />

                {/* charts  */}
                <Route path="/line" element={<RequireAuth><Line /></RequireAuth>} />
                <Route path="/area" element={<RequireAuth><Area /></RequireAuth>} />
                <Route path="/bar" element={<RequireAuth><Bar /></RequireAuth>} />
                <Route path="/pie" element={<RequireAuth><Pie /></RequireAuth>} />
                <Route path="/financial" element={<RequireAuth><Financial /></RequireAuth>} />
                <Route path="/color-mapping" element={<RequireAuth><ColorMapping /></RequireAuth>} />
                <Route path="/pyramid" element={<RequireAuth><Pyramid /></RequireAuth>} />
                <Route path="/stacked" element={<RequireAuth><Stacked /></RequireAuth>} />

              </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;