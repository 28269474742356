import { useState, useContext, useEffect } from "react";
import { ethers } from "ethers";
import ErrorMessage from "./ErrorMessage";
import TxList from "./TxList";
import i18next, { i18n } from 'i18next';
import Button from '@mui/material/Button';
import { db } from "../firebase";
import { doc, updateDoc, increment, getDoc, setDoc, serverTimestamp, arrayUnion, arrayRemove, deleteField } from "firebase/firestore"; 
import Web3 from "web3";
import { FiCreditCard } from "react-icons/fi";
import moment from 'moment'
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';


const startPayment = async ({ setError, setTxs, ether, addr }) => {
  
  try {
    if (!window.ethereum)
      throw new Error("No crypto wallet found. Please install it.");

    //
    const currentUser = JSON.parse(localStorage.getItem("user"))
    const userEmail = currentUser.email
    
    await window.ethereum.send("eth_requestAccounts");
    
    const ethData = await window.ethereum.isConnected()
    const ethData2 = await window.ethereum.networkVersion
    console.log(ethData2)

    // BNB Testnet 97
    const chainId = 1 // Mainnet
    if (window.ethereum.networkVersion == chainId) {
      const dataBase = doc(db, "Users", userEmail)
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      ethers.utils.getAddress(addr);
      
      const tx = await signer.sendTransaction({
        to: addr,
        value: ethers.utils.parseEther(ether),
      });

      console.log({ ether, addr });
      console.log("tx", tx);
      setTxs([tx]);

      const docSnap = await getDoc(dataBase)

      let credit = 0

      if (docSnap.exists()) {
          const getData = docSnap.data()

          // Credit
          const creditData = getData.credit
          console.log(creditData);
          credit = creditData

      } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
      }

      let numCredit = ether * 40000

      let totalCredit = credit + numCredit

      console.log(totalCredit);

      const dateTimeData = moment().format('DD/MM/YYYY hh:mm:ss A');
      console.log(dateTimeData)

      let list = `TOPUP Credit ${ether} ETH`

      const creditHisData = await getDoc(dataBase)

      if (creditHisData.exists()) {
          const data = creditHisData.data()
          const creditHis = data.credit_history

          if (creditHis.length >= 250) {

            await updateDoc(dataBase, {
              credit_history: deleteField()
            })

            await updateDoc(dataBase, {
              credit_history: []
            })

          }

          await updateDoc(dataBase, {
            credit: increment(numCredit),
            credit_history: arrayUnion({
              dateTime: dateTimeData,
              list: list,
              amountCredit: numCredit,
              to: addr,
              tx: tx.hash,
              currentCredit: totalCredit
            })
          })

      }

      window.location.reload()

    } else {
      console.log('Please select the mainnet network')
      setError('Please select the mainnet network')
    }
  } catch (err) {
    // setError(err.message);
    setError('Failed to top up credit')
    console.log(err.message);
  }
};

export default function Payment() {
  const [error, setError] = useState();
  const [txs, setTxs] = useState([]);
  const [transactions, setTransactions] = useState([])
  const [credit, setCredit] = useState()

  useEffect(() => {
    getCryptoWalletAddress()

    const user = JSON.parse(localStorage.getItem("user"))
    getData(user.email)

  }, []);

  // Admin Data
  const [adminData, setAdminData] = useState({
    cryptoAddress: ''
  })

  // Get admin data
  async function getCryptoWalletAddress() {            
    const dataBase = doc(db, "Admin", 'ftx.c1920@gmail.com')
    
    const docSnap = await getDoc(dataBase)

        if (docSnap.exists()) {
            const data = docSnap.data()

            // Crypto wallet address
            const newdata = { ...adminData }
            newdata['cryptoAddress'] = data.cryptoAddress
            setAdminData(newdata)

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
  }

  //
  async function getData(e) {
    const dataBase = doc(db, "Users", e)
    const docSnap = await getDoc(dataBase)

    if (docSnap.exists()) {
        const getData = docSnap.data()

        // Transactions
        const getTran = getData.credit_history
        setTransactions(getTran)

        // Credit
        const creditData2 = getData.credit
        const creditData3 = Math.round(creditData2)
        setCredit(creditData3)

    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    setError();
    await startPayment({
      setError,
      setTxs,
      ether: data.get("ether"),
      addr: adminData.cryptoAddress
    });
  }

  const editing = { allowDeleting: false, allowEditing: false }

  const contextMenuItems = [
    'AutoFit',
    'AutoFitAll',
    'SortAscending',
    'SortDescending',
    'Copy',
    'Edit',
    'Delete',
    'Save',
    'Cancel',
    'PdfExport',
    'ExcelExport',
    'CsvExport',
    'FirstPage',
    'PrevPage',
    'LastPage',
    'NextPage',
  ]

  const creditHisGrid = [
    {
        field: 'dateTime',
        headerText: 'Date Time',
        width: '200',
        textAlign: 'Left',
    },
    {
        field: 'list',
        headerText: 'List',
        width: '150',
        textAlign: 'Left',
    },
    {
        field: 'amountCredit',
        headerText: 'Amount Credit',
        width: '150',
        textAlign: 'Left',
    },
    {
        field: 'to',
        headerText: 'To',
        width: '300',
        textAlign: 'Left',
    },
    {
        field: 'currentCredit',
        headerText: 'Current Credit',
        width: '150',
        textAlign: 'Left',
    },
    {
        field: 'tx',
        headerText: 'Tax',
        width: '450',
        textAlign: 'Left',
    }
  ]


  return (
    <div>
      <form className="m-4 " onSubmit={handleSubmit}>
        <div className="credit-card w-full lg:w-1/2 sm:w-auto shadow-lg mx-auto rounded-xl bg-white">
          <main className="mt-4 p-4">
            <h1 className="text-2xl font-semibold text-gray-700 text-center">
            {i18next.t('เติมเครดิตผ่าน ETH Payment')} <br />
            <p className="text-xs">FuturesX Wallet Address: {adminData.cryptoAddress}</p>
            </h1>
            <div className="">
              <div className="my-3">
                <input
                  name="ether"
                  type="text"
                  className="input input-bordered block w-full focus:ring focus:outline-none"
                  placeholder="Amount in ETH"
                />
              </div>
            </div>
          </main>
          <div className="p-4 justify-center">
            <Button variant="contained" color="success" type="submit">Pay Now</Button>
            <ErrorMessage message={error} />
            <TxList txs={txs} />
          </div>
        </div>
      </form>

      <div className="credit-card w-full lg:w-1/2 sm:w-auto shadow-lg mx-auto rounded-xl bg-white">
        <main className="mt-4 p-4">
          <h1>{i18next.t('เรทการเติมเครดิตผ่าน ETH Payment')}</h1>
          <h1>0.0001 ETH = 4 Credit</h1>
          <h1>0.001 ETH = 40 Credit</h1>
          <h1>0.01 ETH = 400 Credit</h1>
          <h1>0.1 ETH = 4,000 Credit</h1>
          <h1>1 ETH = 40,000 Credit</h1>
        </main>
      </div>
      <br/>
      <br/>
      <div className="credit-card w-full lg:w-1/2 sm:w-auto shadow-lg mx-auto rounded-xl bg-white">
        <main className="mt-4 p-4">
          <h1 className="font-semibold text-xl">{i18next.t('เครดิตปัจจุบันของคุณ')} = {credit}</h1>
        </main>
      </div>

      <br/>
      <br/>

      <div>
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl md:w-1080">
          <div className="flex justify-between">
              <p className="font-semibold text-xl">{i18next.t('Credit History')}</p>
          </div>
          <GridComponent
            id="gridcomp"
            dataSource={transactions}
            allowPaging
            allowSorting
            allowExcelExport
            allowPdfExport
            contextMenuItems={contextMenuItems}
            editSettings={editing}
          >
          <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {creditHisGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
          </ColumnsDirective>
          <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport]} />
          </GridComponent>
        </div>
      </div>

    </div>
  );
}
