import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import i18next from 'i18next';

import './index.css';
import App from './App';
import { ContextProvider } from './contexts/ContextProvider';
import { AuthContextProvider } from './contexts/AuthContext';

import { transitions, positions, Provider as AlertProvider, types } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  types: types.SUCCESS,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

const lang = localStorage.getItem('lang') || 'en'
i18next.changeLanguage(lang)

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <AuthContextProvider>
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
        </AlertProvider>
      </AuthContextProvider>
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);