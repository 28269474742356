import React from 'react';
import { useState, useEffect } from 'react'
import { useContext } from 'react';
import { MdOutlineCancel } from 'react-icons/md';

import { useStateContext } from '../contexts/ContextProvider';
import { cartData } from '../data/dummy';
import { Button2 } from '.';

import i18next from 'i18next';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { doc, updateDoc, increment, getDoc, setDoc, arrayUnion } from "firebase/firestore"; 
import { db } from '../firebase';
import { AuthContext } from '../contexts/AuthContext';
import { v1 as uuidv1 } from 'uuid';
import moment from 'moment'
import { useAlert } from 'react-alert'


const Cart = () => {
  const { setIsClicked, initialState } = useStateContext();

  const [currentPieces, setCurrentPieces] = useState()
  const [creditPrice, setCreditPrice] = useState()
  const [credit2, setCredit2] = useState()
  const [log, setLog] = useState('')
  const [error, setError] = useState(false)
  const { currentUser } = useContext(AuthContext)
  const email = currentUser.email

  const dataBase = doc(db, "Users", email)

  //
  const alert = useAlert()


  useEffect(() => {
    
    getCredit(dataBase)

  }, [dataBase]);

  function handleCreditData(e){
    let pieces = e.target.value
    
    if (pieces <= 0) {
      pieces = 1
      setCurrentPieces(pieces)
    } else {
      setCurrentPieces(pieces)
    }

    const priceBot = 2000
    setCreditPrice(priceBot * pieces)
  }

  async function creditPayment() {
    if (creditPrice > credit2) {
      setLog("You don't have enough credit")
      setError(true)
    } else {
      if (creditPrice > 1) {
        
        const dateTimeData = moment().format('LLL');

        alert.show('Success!')

        let totalCredit = credit2 - creditPrice
        
        await updateDoc(dataBase, {
          credit: increment(-creditPrice),
          credit_history: arrayUnion({
            dateTime: dateTimeData,
            list: `Binances Futures ${currentPieces}`,
            amountCredit: creditPrice,
            to: 'FuturesX',
            tx: '',
            currentCredit: totalCredit
          })
        })
        setLog("Success")

        //

        setError(false)
        getCredit()
        buyBot()

        setIsClicked({ ...initialState, 'botCart': false });

      } else {
        setLog("You entered an invalid value")
        setError(true)
      }
    }
  }

  // Add Bot
  async function buyBot() {
    let numBot = currentPieces

    for (let i = 0; i < numBot; i++) {
      const id = uuidv1()
      addBot(id)
    }
  }

  async function addBot(e) {
    const botDataBase = doc(db, "Users", email, 'Bot', e)
    
    await setDoc(botDataBase, {
      name: e,
      token: e,
      bot: 'BinanceFutures',
      api_key: '',
      secret_key: '',
      leverage: 5,
      limitOrders: 1,
      currentOrder: 1,
      longNoStopLoss: 'No',
      shortNoStopLoss: 'No',
      firstOrderDelay: 10,
      orderDelayBetween: 10,
      status: 'New',
      lineToken: '',
      lastIPLogin: '',
      lastUpdate: '',
      totalBalances: 0,
      lineLang: 'en'
    })

    addNewBotOrder(e)
    addNewBotPNL(e)
    addNewBotLogs(e)
    addNewBotUI(e)
  }

  // Add New Bot Order
  async function addNewBotOrder(e) {
    const dataBase = doc(db, "Users", email, 'Orders', e)
    
    await setDoc(dataBase, {
        data: [{
            numOrder: 1,
            amount: 10,
            takeProfit: 5,
            stopLoss: 5,
            symbol: 'BTCUSDT',
            signal: 'Technical Trader'
        }]
    })
  }

  // Add New Bot PNL
  async function addNewBotPNL(e) {
    const dataBase = doc(db, "Users", email, 'PNL', e)
    
    await setDoc(dataBase, {
        allPNL: 0,
        listPNL: [{
            symbol: 'BTCUSDT',
            pnl: 0
        }],
        pnlHistory: [{
          dateTime: '',
          month: '',
          pnl: 0
      }]
    })
  }

  // Add New Bot Logs
  async function addNewBotLogs(e) {
    const dataBase = doc(db, "Users", email, 'Logs', e)
    
    await setDoc(dataBase, {
        error: []
    })
}

// Add New Bot UI
async function addNewBotUI(e) {
    const dataBase = doc(db, "Users", email, 'UI', e)
    
    await setDoc(dataBase, {
      currentOrders: [{
        updateTime: '',
        symbol: '',
        side: '',
        qty: '',
        entryPrice: '',
        markPrice: '',
        margin: '',
        marginType: '',
        leverage: '',
        pnl: ''
      }],
      openOrders: [{
          updateTime: '',
          symbol: '',
          side: '',
          qty: '',
          entryPrice: '',
          markPrice: '',
          margin: '',
          marginType: '',
          leverage: '',
      }],
      updateUI: {
          status: '',
          unrealized: 0.0,
          roi: 0.0,
          updateTime: '',
          totalBalance: 0.0,
          availableBalance: 0.0,
          usdtBalances: 0.0,
          lastSymbol: '',
          currentOrder: 1,
          lastOrderSide: ''
      }
    })
}

  // Get a document
  async function getCredit(e) {
    const docSnap = await getDoc(e)

    if (docSnap.exists()) {
        const creditData = docSnap.data()

        const creditData2 = creditData.credit
        const creditData3 = Math.round(creditData2)
        setCredit2(creditData3)

    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
  }

  return (
    <div className="bg-half-transparent w-full fixed nav-item top-0 right-0 ">
      <div className="float-right h-screen  duration-1000 ease-in-out dark:text-gray-200 transition-all dark:bg-[#484B52] bg-white md:w-400 p-8">
        <div className="flex justify-between items-center">
          <p className="font-semibold text-lg">{i18next.t('Shopping Cart')}</p>
          <Button2
            icon={<MdOutlineCancel />}
            color="rgb(153, 171, 180)"
            bgHoverColor="light-gray"
            size="2xl"
            borderRadius="50%"
          />
        </div>
        {cartData?.map((item, index) => (
          <div key={index}>
            <div>
              <div className="flex items-center   leading-8 gap-5 border-b-1 border-color dark:border-gray-600 p-4">
                <img className="rounded-lg h-80 w-24" src={item.image} alt="" />
                <div>
                  <p className="font-semibold ">{item.name}</p>
                  <p className="text-gray-600 dark:text-gray-400 text-sm font-semibold">{item.category}</p>
                  <div className="flex gap-4 mt-2 items-center">
                    <div className="flex items-center border-1 border-r-0 border-color rounded">
                      <TextField
                      id="amount"
                      label="Number of Pieces"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      onChange={handleCreditData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="mt-3 mb-3">
          <div className="flex justify-between items-center mt-3">
            <p className="text-gray-500 dark:text-gray-200">{i18next.t('Total Pieces')}</p>
            <p className="font-semibold">{currentPieces}</p>
          </div>
          <div className="flex justify-between items-center mt-3">
            <p className="text-gray-500 dark:text-gray-200">{i18next.t('Total Credit')}</p>
            <p className="font-semibold">{creditPrice}</p>
          </div>
          <div className="flex justify-between items-center mt-3">
            <p className="text-gray-500 dark:text-gray-200">{i18next.t('Your Credit')}</p>
            <p className="font-semibold">{credit2}</p>
          </div>
        </div>
        <div className="mt-5">
          <Button variant="contained" color="success" size='large' onClick={creditPayment}>{i18next.t('BUY Product')}</Button>
        </div>
        <br />
        <br />
        { error ? <h1 className="text-red-600 -webkit-font-smoothing: antialiased;">{log}</h1> : <h1 className="text-green-600 -webkit-font-smoothing: antialiased;">{log}</h1>}
      </div>
    </div>
  );
};

export default Cart;
