import React from 'react'
import { useState } from 'react'
import { auth } from '../firebase';
import { createUserWithEmailAndPassword } from "firebase/auth";
import i18next, { i18n } from 'i18next';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';


const Register = () => {
    const [error, setError] = useState(false)
    const [logs, setLogs] = useState("")
    const [success, setSuccess] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const navitage = useNavigate()

    const {dispatch} = useContext(AuthContext)

    const handleAddUser = (e) => {
        e.preventDefault()
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                dispatch({type:"LOGIN", payload: user})
                navitage('/')
                setSuccess(true)
                setError(false)
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setError(true)
                setLogs(errorMessage)
                // ..
            });
    }
  
    return (
    <div className='login'>
        <form onSubmit={handleAddUser}>
            <h1 className="text-xl font-semibold">{i18next.t('สมัครสมาชิก')}</h1>
            <input type="email" placeholder='email' onChange={e => setEmail(e.target.value)}></input>
            <input type="password" placeholder='password' onChange={e => setPassword(e.target.value)}></input>
            <button type='submit'>{i18next.t('สมัครสมาชิก')}</button>
            {error && <span>{logs}</span>} 
            {success && <span>Success</span>} 
        </form>
    </div>
  )
}

export default Register