import React from 'react';
import { useState, useEffect } from 'react'
import { GoCreditCard } from 'react-icons/go';
import Button from '@mui/material/Button';
import { useStateContext } from '../contexts/ContextProvider';
import Binance from 'binance-api-react-native'
import { FaBtc, FaEthereum } from "react-icons/fa";
import { SiBinance} from "react-icons/si";
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import { getDoc, getDocs, doc, collection } from "firebase/firestore"; 
import { db } from '../firebase';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';

const Ecommerce = () => {
  const { currentColor } = useStateContext();
  const [admin, setAdmin] = useState(false)
  const [btcPrice, setbtcPrice] = useState()
  const [ethPrice, setethPrice] = useState()
  const [bnbPrice, setbnbPrice] = useState()

  const [credit, setCredit] = useState()
  const [transactions, setTransactions] = useState([])
  const [loginHistory, setloginHistory] = useState([])

  const [botInfo, setBotInfo] = useState([])
  const [allBotPNL, setAllBotPNL] = useState()
  const [allBotBalances, setAllBotBalances] = useState()

  //
  const editing = { allowDeleting: false, allowEditing: false };

  const navitage = useNavigate()

  const timeData = Date().toLocaleString()

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))
    checkAdmin(user.email)
    getBooks()
    getCredit(user.email)
    getData(user.email)
    updateUserDatabase(user)

    getBotPNL(user.email)
  }, []);

  function checkAdmin(user) {
      const isAdmin = 'ftx.c1920@gmail.com'
      if (isAdmin === user) {
          setAdmin(true)
      } else {
          setAdmin(false)
      }
  }

  const client = Binance()
    
  async function getBooks() {
      const btcData = await client.book({ symbol: 'BTCUSDT' })
      const btcData2 = btcData.bids[0].price
      const btcData3 = Math.round(btcData2 * 100) / 100
      setbtcPrice(btcData3)

      const ethData = await client.book({ symbol: 'ETHUSDT' })
      const ethData2 = ethData.bids[0].price
      const ethData3 = Math.round(ethData2 * 100) / 100
      setethPrice(ethData3)

      const bnbData = await client.book({ symbol: 'BNBUSDT' })
      const bnbData2 = bnbData.bids[0].price
      const bnbData3 = Math.round(bnbData2 * 100) / 100
      setbnbPrice(bnbData3)
  }

  function totopUp() {
    navitage('/payment')
  }

  async function updateUserDatabase(user) {
    const userUID = user.uid
  }

  // Get a document
  async function getCredit(e) {
    const dataBase = doc(db, "Users", e)
    const docSnap = await getDoc(dataBase)

    if (docSnap.exists()) {
        const creditData = docSnap.data()

        // Credit
        const creditData2 = creditData.credit
        const creditData3 = Math.round(creditData2)
        setCredit(creditData3)

        //

    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
  }

  async function getData(e) {
    const dataBase = doc(db, "Users", e)
    const docSnap = await getDoc(dataBase)

    if (docSnap.exists()) {
        const getData = docSnap.data()

        // Transactions
        const combinedArrays = Object.entries(getData).reduce((acc, [key, value]) => {
          if (key !== 'loginHistory' && key !== 'credit_history_list' && key !== 'error_logs' && Array.isArray(value)) {
            return acc.concat(value);
          }
          return acc;
        }, []);

        setTransactions(combinedArrays)

        // Login History
        const getLoginHis = getData.loginHistory
        setloginHistory(getLoginHis)

    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
  }

  // Get ALL Bot PNL DATA
  async function getBotPNL(e) {
    const querySnapshot = await getDocs(collection(db, "Users", e, 'PNL'))

    let new_data = []
    let allBotData = []
    let allBotPnlData = 0
    let allBotBalancesData = 0

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const data = doc.data()

      // New Bot Data
      const get_data = {
        botName: doc.id,
        pnl: data.allPNL
      }

      // Set All Bot PNL
      allBotPnlData += data.allPNL

      // Set All Bot Data
      new_data.push(get_data)

    });

    // Total Balance
    const querySnapshot2 = await getDocs(collection(db, "Users", e, 'Bot'))

    querySnapshot2.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const data = doc.data()

      for (let i = 0; i < new_data.length; i++) {
        
        if (new_data[i].botName === data.name) {
          
          // Set All Bot PNL
          allBotBalancesData += data.totalBalances
          
          // New Bot Data
          const allInfoData = {
            botName: new_data[i].botName,
            pnl: new_data[i].pnl,
            balances: data.totalBalances
          }

          allBotData.push(allInfoData)
        }

      }

    })

    const totalBalancesData = Math.round(allBotBalancesData)
    const totalPNLData = Math.round(allBotPnlData)

    setBotInfo(allBotData)
    setAllBotPNL(totalPNLData)
    setAllBotBalances(totalBalancesData)
  }


  //
  const contextMenuItems = [
    'AutoFit',
    'AutoFitAll',
    'SortAscending',
    'SortDescending',
    'Copy',
    'Edit',
    'Delete',
    'Save',
    'Cancel',
    'PdfExport',
    'ExcelExport',
    'CsvExport',
    'FirstPage',
    'PrevPage',
    'LastPage',
    'NextPage',
  ]

  //
  const loginHistoryGrid = [
    {
        field: 'dateTime',
        headerText: 'Date Time',
        width: '300',
        textAlign: 'Left',
    },
    {
        field: 'ipLogin',
        headerText: 'IP Login',
        width: '150',
        textAlign: 'Left',
    },
    {
      field: 'cityLogin',
      headerText: 'City Login',
      width: '150',
      textAlign: 'Left',
  }
  ]

  const botGrid = [
    {
        field: 'botName',
        headerText: 'Bot Name',
        width: '300',
        textAlign: 'Left',
    },
    {
        field: 'pnl',
        headerText: 'PNL',
        width: '150',
        textAlign: 'Left',
    },
    {
      field: 'balances',
      headerText: 'TotalBalances',
      width: '150',
      textAlign: 'Left',
    }
  ]

  const creditHisGrid = [
    {
        field: 'dateTime',
        headerText: 'Date Time',
        width: '200',
        textAlign: 'Left',
    },
    {
        field: 'list',
        headerText: 'List',
        width: '150',
        textAlign: 'Left',
    },
    {
        field: 'amountCredit',
        headerText: 'Amount Credit',
        width: '150',
        textAlign: 'Left',
    },
    {
        field: 'to',
        headerText: 'To',
        width: '300',
        textAlign: 'Left',
    },
    {
        field: 'currentCredit',
        headerText: 'Current Credit',
        width: '150',
        textAlign: 'Left',
    },
    {
        field: 'tx',
        headerText: 'Tax',
        width: '450',
        textAlign: 'Left',
    }
  ]

  // Dump Data
  const allBotData = [{
    pnl: 3000.54,
    symbol: 'ETHUSDT'
  }]


  return (
    <div className="mt-24">

      <div className="flex flex-wrap lg:flex-nowrap justify-center ">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-bold text-gray-400">{i18next.t('เครดิต')}</p>
              <p className="text-4xl">{credit}</p>
            </div>

            <button
              type="button"
              style={{ backgroundColor: currentColor }}
              className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4"
            >
              <GoCreditCard />
            </button>
          </div>

          <div className="mt-6">
          <Button variant="contained" onClick={totopUp}>{i18next.t('เติมเครดิต')}</Button>
          </div>

        </div>

        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
            
            {/* BTC*/}
            <div key='BTC' className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl ">
              <button
                type="button"
                style={{ color: '#FFFFFF', backgroundColor: '#FF9039' }}
                className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
              >
                <FaBtc />
              </button>

              <p className="mt-3">
                <span className="text-lg font-semibold">{btcPrice}</span>
              </p>

              <p className="text-sm text-gray-400  mt-1">BTC</p> <br />
              <p className="text-sm text-gray-400  mt-1">{timeData}</p>
            </div>

            {/* ETH*/}
            <div key='ETH' className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl ">
              <button
                type="button"
                style={{ color: 'rgb(0, 0, 0)', backgroundColor: '#DEDEDE' }}
                className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
              >
                <FaEthereum />
              </button>

              <p className="mt-3">
                <span className="text-lg font-semibold">{ethPrice}</span>
              </p>

              <p className="text-sm text-gray-400  mt-1">ETH</p><br />
              <p className="text-sm text-gray-400  mt-1">{timeData}</p>
            </div>

            {/* BNB*/}
            <div key='BNB' className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl ">
              <button
                type="button"
                style={{ color: '#FFFFFF', backgroundColor: '#F1BC0A' }}
                className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
              >
                <SiBinance />
              </button>

              <p className="mt-3">
                <span className="text-lg font-semibold">{bnbPrice}</span>
              </p>

              <p className="text-sm text-gray-400  mt-1">BNB</p><br />
              <p className="text-sm text-gray-400  mt-1">{timeData}</p>
            </div>
        </div>
      </div>

      <br/>
      <br/>
      <br/>

      <div>
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl md:w-780">
          <div className="flex justify-between">
              <p className="font-semibold text-xl">{i18next.t('Login History')}</p>
          </div>
          <GridComponent
            id="gridcomp"
            dataSource={loginHistory}
            allowPaging={false}
            allowSorting
            allowExcelExport
            allowPdfExport
            contextMenuItems={contextMenuItems}
            editSettings={editing}
            pageSettings={{ pageSize: 0 }}
          >
          <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {loginHistoryGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
          </ColumnsDirective>
          <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport]} />
          </GridComponent>
        </div>
      </div>

      <div>
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl md:w-780">
          <div className="flex justify-between">
              <p className="font-semibold text-xl">{i18next.t('Bot Information')}</p>
          </div>
          <GridComponent
            id="gridcomp"
            dataSource={botInfo}
            allowPaging
            allowSorting
            allowExcelExport
            allowPdfExport
            contextMenuItems={contextMenuItems}
            editSettings={editing}
          >
          <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {botGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
          </ColumnsDirective>
          <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport]} />
          </GridComponent>
        </div>
      </div>

      <div className=" rounded-2xl md:w-400 p-4 m-3" style={{ backgroundColor: currentColor }}>
        <div className="flex justify-between items-center ">
          <p className="font-semibold text-white text-2xl">{i18next.t('Total PNL')}</p>
            <div>
              <p className="text-6xl text-white font-semibold mt-8">{allBotPNL}</p>
              <p className="text-gray-200">USDT</p>
            </div>
        </div>
      </div>

      <div className=" rounded-2xl md:w-400 p-4 m-3" style={{ backgroundColor: currentColor }}>
        <div className="flex justify-between items-center ">
          <p className="font-semibold text-white text-2xl">{i18next.t('Total Balances')}</p>
            <div>
              <p className="text-6xl text-white font-semibold mt-8">{allBotBalances}</p>
              <p className="text-gray-200">USDT</p>
            </div>
        </div>
      </div>

      <br/>
      <br/>

      <div>
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl md:w-1080">
          <div className="flex justify-between">
              <p className="font-semibold text-xl">{i18next.t('Credit History')}</p>
          </div>
          <GridComponent
            id="gridcomp"
            dataSource={transactions}
            allowPaging
            allowSorting
            allowExcelExport
            allowPdfExport
            contextMenuItems={contextMenuItems}
            editSettings={editing}
            sortSettings={{
              columns: [
                {
                  field: "dateTime",
                  direction: "Descending"
                },
              ],
              allowUnsort: false,
            }}
          >
          <ColumnsDirective>
            {creditHisGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
            <ColumnDirective
              field="dateTime"
              headerText="Date Time"
              format="dd/MM/yyyy HH:mm:ss"
              type="datetime"
              valueAccessor={(field) => {
                return new Date(field).toLocaleString();
              }}
            />
          </ColumnsDirective>
          <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport]} />
          </GridComponent>
        </div>
      </div>

    </div>
  );
};

export default Ecommerce;
