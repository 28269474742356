import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { db } from '../firebase';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword } from "firebase/auth";
import i18next, { i18n } from 'i18next';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Header } from '../components';

import { AuthContext } from '../contexts/AuthContext';
import { collection, addDoc, getDoc, doc, setDoc, updateDoc, increment, arrayUnion, arrayRemove, deleteDoc } from "firebase/firestore";

import { useAlert } from 'react-alert'

import { v1 as uuidv1 } from 'uuid';

const UserManagement = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    //* Alert Popup
    const alert = useAlert()

    //* ADD User Form
    const [formEmail, setFormEmail] = useState('')
    const [formPassword, setFormPassword] = useState('')

    //* DELETE User Form
    const [formEmail2, setFormEmail2] = useState('')

    //* Fee
    const [feeTrade, setFeeTrade] = useState()

    function handleSetFeeTrade(e) {
        let value = Number(e.target.value)

        setFeeTrade(value)
    }

    // Current admin
    const { currentUser } = useContext(AuthContext)
    const emailAdmin = currentUser.email

    const dataBase = doc(db, "Admin", emailAdmin)

    // Admin Data
    const [adminData, setAdminData] = useState({
        cryptoAddress: ''
    })

    let cryptoAddressInput = `Address ปัจจุบัน: ${adminData.cryptoAddress}`

    // Crypto Wallet Address
    const [cryptoAddress, setCryptoAddress] = useState('')

    useEffect(() => {
      getAdminData()
    }, []);


    const handleRegister = (e) => {
        console.log(formEmail)
        console.log(formPassword)
        e.preventDefault()
        createUserWithEmailAndPassword(auth, formEmail, formPassword)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                const uidData = user.uid
                const emailData = user.email

                addNewUser(uidData)

                alert.show(`เพิ่มผู้ใช้: ${emailData}`)
        
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
    
                alert.show(errorMessage)

                setFormEmail('')
                setFormPassword('')
                // ..
            });
    }

    //* DELETE User Button
    async function deleteUser() {
        console.log(`Delete User: ${formEmail2}`)
        
        await deleteDoc(doc(db, "Users", formEmail2));

        alert.show('ลบผู้ใช้สำเร็จ!')

        setFormEmail2('')

    }

    async function addNewUser(e) {
        const dataBase = doc(db, "Users", formEmail)
        const id = uuidv1()
        
        await setDoc(dataBase, {
            credit: 0,
            credit_history: [],
            email: formEmail,
            loginHistory: [],
            uid: e,
            bot: [{
                name: id,
                token: id,
                bot: 'BinanceFutures',
                api_key: '',
                secret_key: '',
                leverage: 5,
                limitOrders: 1,
                currentOrder: 1,
                longNoStopLoss: 'No',
                shortNoStopLoss: 'No',
                firstOrderDelay: 10,
                orderDelayBetween: 10,
                status: 'New',
                lineToken: '',
                lastIPLogin: '',
                lastUpdate: '',
                totalBalances: 0,
                lineLang: 'en'
            }],
            referCode: ''
        })

        alert.show('เพิ่มผู้ใช้สำเร็จ!')

        addNewBotOrder(id)
        addNewBotPNL(id)
        addNewBotLogs(id)
        addNewBotUI(id)
    }

    // Add New Bot Order
    async function addNewBotOrder(e) {
        const dataBase = doc(db, "Users", formEmail, 'Orders', e)
        
        await setDoc(dataBase, {
            data: [{
                numOrder: 1,
                amount: 10,
                takeProfit: 5,
                stopLoss: 5,
                symbol: 'BTCUSDT',
                signal: 'Technical Trader'
            }]
        })
    }

    // Add New Bot PNL
    async function addNewBotPNL(e) {
        const dataBase = doc(db, "Users", formEmail, 'PNL', e)
        
        await setDoc(dataBase, {
            allPNL: 0,
            listPNL: [{
                symbol: 'BTCUSDT',
                pnl: 0
            }],
            pnlHistory: [{
                dateTime: '',
                month: '',
                pnl: 0
            }]
        })
    }

    // Add New Bot Logs
    async function addNewBotLogs(e) {
        const dataBase = doc(db, "Users", formEmail, 'Logs', e)
        
        await setDoc(dataBase, {
            error: []
        })
    }

    // Add New Bot UI
    async function addNewBotUI(e) {
        const dataBase = doc(db, "Users", formEmail, 'UI', e)
        
        await setDoc(dataBase, {
            currentOrders: [{
                updateTime: '',
                symbol: '',
                side: '',
                qty: '',
                entryPrice: '',
                markPrice: '',
                margin: '',
                marginType: '',
                leverage: '',
                pnl: ''
            }],
            openOrders: [{
                updateTime: '',
                symbol: '',
                side: '',
                qty: '',
                entryPrice: '',
                markPrice: '',
                margin: '',
                marginType: '',
                leverage: '',
            }],
            updateUI: {
                status: '',
                unrealized: 0.0,
                roi: 0.0,
                updateTime: '',
                totalBalance: 0.0,
                availableBalance: 0.0,
                usdtBalances: 0.0,
                lastSymbol: '',
                currentOrder: 1,
                lastOrderSide: ''
            }
        })
    }

    // Get admin data
    async function getAdminData() {            
        const docSnap = await getDoc(dataBase)

            if (docSnap.exists()) {
                const data = docSnap.data()

                // Crypto wallet address
                const newdata = { ...adminData }
                newdata['cryptoAddress'] = data.cryptoAddress
                setAdminData(newdata)

            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
    }

    // Update crypto wallet button
    async function updateCryptoWallet() {            
        const docSnap = await getDoc(dataBase)

            if (docSnap.exists()) {
                const data = docSnap.data()
                console.log(data);

                await updateDoc(dataBase, {
                    cryptoAddress: cryptoAddress
                })

                alert.show('เปลี่ยนกระเป๋าสำเร็จ!')

                setCryptoAddress('')

                getAdminData()

            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
    }
  
    return (
    <div className="mt-24">
        <div>
            <h1 className="text-4xl font-semibold flex gap-1 flex-wrap justify-center">FuturesX Admin</h1>
            <h1 className="text-2xl font-semibold flex gap-1 flex-wrap justify-center">ระบบจัดการ User</h1> <br />
            <h1 className="text-xl font-semibold flex gap-1 flex-wrap justify-center">เพิ่ม User</h1>
            <div className='flex gap-10 flex-wrap justify-center'>
            <TextField
                    label="Email"
                    id="formEmail"
                    type="email"
                    sx={{ m: 1, width: '25ch' }}
                    InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    onChange={e => setFormEmail(e.target.value)} value={formEmail}
            />
            </div>
            <div className='flex gap-10 flex-wrap justify-center'>
            <TextField
                    label="Password"
                    id="user"
                    sx={{ m: 1, width: '25ch' }}
                    InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    onChange={e => setFormPassword(e.target.value)} value={formPassword}
            />
            </div>
            <div className='flex gap-10 flex-wrap justify-center'>
                <Button variant="outlined" color="success" onClick={handleRegister} >Add User</Button>
            </div> <br />

            {/* DELETE User */}
            <h1 className="text-xl font-semibold flex gap-1 flex-wrap justify-center">ลบ User</h1>
            <div className='flex gap-10 flex-wrap justify-center'>
            <TextField
                    label="Email"
                    id="formEmail2"
                    type="email"
                    sx={{ m: 1, width: '25ch' }}
                    InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    onChange={e => setFormEmail2(e.target.value)} value={formEmail2}
            />
            </div>
            <div className='flex gap-10 flex-wrap justify-center'>
                <Button variant="outlined" color="error" onClick={deleteUser} >Delete User</Button>
            </div> <br />

            {/*Crypto Wallet*/}
            <h1 className="text-xl font-semibold flex gap-1 flex-wrap justify-center">เปลี่ยน Crypto Wallet สำหรับเติม Credit</h1>
            <div className='flex gap-10 flex-wrap justify-center'>
            <TextField
                    label="Address"
                    id="cryptoAddress"
                    sx={{ m: 1, width: '48ch' }}
                    InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    onChange={e => setCryptoAddress(e.target.value)} value={cryptoAddress}
            />
            </div>
            <div className='flex gap-10 flex-wrap justify-center'>
                <Header category={cryptoAddressInput}/>
            </div>
            <div className='flex gap-10 flex-wrap justify-center'>
                <Button variant="outlined" color="secondary" onClick={updateCryptoWallet} >Add Wallet</Button>
            </div>

            <br />

            {/*Fee Trade*/}
            <h1 className="text-xl font-semibold flex gap-1 flex-wrap justify-center">เปลี่ยนค่าธรรมเนียมการเทรด</h1>
            <p className="text-xm flex gap-1 flex-wrap justify-center">จะหักต่อเมื่อ User เทรดแล้วมีกำไรเท่านั้น</p>
            <br />
            <div className='flex gap-10 flex-wrap justify-center'>
            <TextField
                    id="fee"
                    label="Fee"
                    type="number"
                    InputLabelProps={{
                    shrink: true,
                    }}
                    onChange={(e) => handleSetFeeTrade(e)} value={feeTrade}
            />
            </div>
            <div className='flex gap-10 flex-wrap justify-center'>
                <Header category="ค่าธรรมเนียมปัจจุบัน: 0.25%"/>
            </div>
            <div className='flex gap-10 flex-wrap justify-center'>
                <Button variant="outlined" color="secondary" onClick={updateCryptoWallet} >Add Fee</Button>
            </div>

        </div>
    </div>
    
  )
}

export default UserManagement