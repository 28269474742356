import React from 'react'
import { useState } from 'react'
import './login.scss'
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import i18next from 'i18next';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { doc, setDoc, getDoc, updateDoc, arrayUnion, deleteField } from "firebase/firestore"; 
import { db } from '../firebase';

import moment from 'moment'
import axios from 'axios'

import { v1 as uuidv1 } from 'uuid';

const Login = () => {
    const [logs, setLogs] = useState("")

    const [formEmail, setFormEmail] = useState('')
    const [formPassword, setFormPassword] = useState('')
    const [formPassword2, setFormPassword2] = useState('')

    //* Refer CODE
    const [referCode, setReferCode] = useState('')

    const navitage = useNavigate()

    const {dispatch} = useContext(AuthContext)

    const handleLogin = (e) => {
        e.preventDefault()

        signInWithEmailAndPassword(auth, formEmail, formPassword)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;

            addLoginData()

            dispatch({type:"LOGIN", payload: user})
            navitage('/')

            // Set User History Login

            // ...
        })
        .catch((error) => {
            const errorMessage = error.message;
            setLogs(errorMessage)
            // ..
        })
    }

    const handleRegister = (e) => {
        e.preventDefault()

        if (formPassword === formPassword2) {
            createUserWithEmailAndPassword(auth, formEmail, formPassword)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                const uidData = user.uid

                addNewUser(uidData)

                addLoginData()
        
                dispatch({type:"LOGIN", payload: user})
                navitage('/')
                // ...
            })
            .catch((error) => {
                const errorMessage = error.message;
                setLogs(errorMessage)
                // ..
            });
        } else {
            setLogs('Two passwords do not match')
        }
    }

    async function addNewUser(e) {
        const dataBase = doc(db, "Users", formEmail)
        const id = uuidv1()
        
        await setDoc(dataBase, {
            credit: 0,
            credit_history: [],
            email: formEmail,
            loginHistory: [],
            uid: e,
            referCode: referCode
        })

        addNewBot(id)
        addNewBotOrder(id)
        addNewBotPNL(id)
        addNewBotLogs(id)
        addNewBotUI(id)
    }

    //* Add New Bot
    async function addNewBot(e) {
        const dataBase = doc(db, "Users", formEmail, 'Bot', e)
        
        await setDoc(dataBase, {
            name: e,
            token: e,
            bot: 'BinanceFutures',
            api_key: '',
            secret_key: '',
            leverage: 5,
            limitOrders: 1,
            currentOrder: 1,
            longNoStopLoss: 'No',
            shortNoStopLoss: 'No',
            firstOrderDelay: 10,
            orderDelayBetween: 10,
            status: 'New',
            lineToken: '',
            lastIPLogin: '',
            lastUpdate: '',
            totalBalances: 0,
            lineLang: 'en'
        })
    }

    // Add New Bot Order
    async function addNewBotOrder(e) {
        const dataBase = doc(db, "Users", formEmail, 'Orders', e)
        
        await setDoc(dataBase, {
            data: [{
                numOrder: 1,
                amount: 10,
                takeProfit: 5,
                stopLoss: 5,
                symbol: 'BTCUSDT',
                signal: 'Technical Trader'
            }]
        })
    }

    // Add New Bot PNL
    async function addNewBotPNL(e) {
        const dataBase = doc(db, "Users", formEmail, 'PNL', e)
        
        await setDoc(dataBase, {
            allPNL: 0,
            listPNL: [{
                symbol: 'BTCUSDT',
                pnl: 0
            }],
            pnlHistory: [{
                dateTime: '',
                month: '',
                pnl: 0
            }]
        })
    }

    // Add New Bot Logs
    async function addNewBotLogs(e) {
        const dataBase = doc(db, "Users", formEmail, 'Logs', e)
        
        await setDoc(dataBase, {
            error: []
        })
    }

    // Add New Bot UI
    async function addNewBotUI(e) {
        const dataBase = doc(db, "Users", formEmail, 'UI', e)
        
        await setDoc(dataBase, {
            currentOrders: [{
                updateTime: '',
                symbol: '',
                side: '',
                qty: '',
                entryPrice: '',
                markPrice: '',
                margin: '',
                marginType: '',
                leverage: '',
                pnl: ''
            }],
            openOrders: [{
                updateTime: '',
                symbol: '',
                side: '',
                qty: '',
                entryPrice: '',
                markPrice: '',
                margin: '',
                marginType: '',
                leverage: '',
            }],
            updateUI: {
                status: '',
                unrealized: 0.0,
                roi: 0.0,
                updateTime: '',
                totalBalance: 0.0,
                availableBalance: 0.0,
                usdtBalances: 0.0,
                lastSymbol: '',
                currentOrder: 1,
                lastOrderSide: ''
            }
        })
    }

    // Get Data Firebase
    async function addLoginData() {
        const dataBase = doc(db, "Users", formEmail)

        const dateTimeData = moment().format('LLL');

        const res = await axios.get('https://geolocation-db.com/json/')
        const resData = res.data
        const ipData = resData.IPv4
        const cityData = resData.city

        // Check Login Logs
        const loginHisData = await getDoc(dataBase)

        if (loginHisData.exists()) {
            const data = loginHisData.data()
            const loginHis = data.loginHistory
  
            if (loginHis.length >= 250) {
  
              await updateDoc(dataBase, {
                loginHistory: deleteField()
              })
  
              await updateDoc(dataBase, {
                loginHistory: []
              })
  
            } 
        
            await updateDoc(dataBase, {
                loginHistory: arrayUnion({
                dateTime: dateTimeData,
                ipLogin: ipData,
                cityLogin: cityData
                })
            })
        }

        window.location.reload()
    }
      
  
    return (
    <div>
        <div>
            <br /><br /><br /><br /><br /><br />
            <h1 className="text-4xl font-semibold flex gap-1 flex-wrap justify-center">FuturesX</h1>
            <h1 className="text-2xl font-semibold flex gap-1 flex-wrap justify-center">CryptoBot Trade</h1> <br />
            <div className='flex gap-10 flex-wrap justify-center'>
            <TextField
                    label={i18next.t('Email')}
                    id="Email"
                    type="email"
                    sx={{ m: 1, width: '25ch' }}
                    InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    onChange={e => setFormEmail(e.target.value)}
            />
            </div>
            <div className='flex gap-10 flex-wrap justify-center'>
            <TextField
                    label={i18next.t('Password')}
                    id="Password"
                    type="password"
                    sx={{ m: 1, width: '25ch' }}
                    InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    onChange={e => setFormPassword(e.target.value)}
            />
            </div>
            
            <div className='flex gap-10 flex-wrap justify-center'>
                <Button variant="outlined" color="success" onClick={handleLogin} >{i18next.t('Login')}</Button>
            </div>

            <br />
            <br />

            {/*
            <div className='flex gap-10 flex-wrap justify-center'>
            <TextField
                    label={i18next.t('Email')}
                    id="Email2"
                    type="email"
                    sx={{ m: 1, width: '25ch' }}
                    InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    onChange={e => setFormEmail(e.target.value)}
            />
            </div>
            <div className='flex gap-10 flex-wrap justify-center'>
            <TextField
                    label={i18next.t('Password')}
                    id="Password2"
                    type="password"
                    sx={{ m: 1, width: '25ch' }}
                    InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    onChange={e => setFormPassword(e.target.value)}
            />
            </div>
            <div className='flex gap-10 flex-wrap justify-center'>
            <TextField
                    label={i18next.t('Password')}
                    id="Password3"
                    type="password"
                    sx={{ m: 1, width: '25ch' }}
                    InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    onChange={e => setFormPassword2(e.target.value)}
            />
            </div>
            <div className='flex gap-10 flex-wrap justify-center'>
            <TextField
                    label={i18next.t('Refer Code')}
                    id="referCode"
                    type="text"
                    sx={{ m: 1, width: '25ch' }}
                    InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    onChange={e => setReferCode(e.target.value)}
            />
            </div>

            <div className='flex gap-10 flex-wrap justify-center'>
                <Button variant="outlined" color="secondary" onClick={handleRegister} >{i18next.t('Register')}</Button>
            </div> 
            */}
            
            <br />

            <div className='flex gap-10 flex-wrap justify-center'>
                <h1>{logs}</h1>
            </div>
        </div>
    </div>
  )
}

export default Login