import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { useStateContext } from '../contexts/ContextProvider';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { Header } from '../components';
import Button from '@mui/material/Button';
import i18next from 'i18next';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../contexts/AuthContext';
import { db } from '../firebase';
import { collection, getDoc, getDocs, doc, setDoc, updateDoc, arrayUnion, arrayRemove, deleteDoc, onSnapshot } from "firebase/firestore";


const FuturesX = () => {

    const {setActiveMenu } = useStateContext()
    const { currentUser } = useContext(AuthContext)
    const email = currentUser.email

    const [allBotData, setAllBotData] = useState([])
    const [ordersData, setOrdersData] = useState([])

    //* Bot UI Data
    const [botUiData, setBotUiData] = useState([])
    const [openOrdersData, setOpenOrdersData] = useState([])
    const [currentOrdersData, setCurrentOrdersData] = useState([])
    const [errorLogsData, setErrorLogsData] = useState([])

    const editing = { allowDeleting: false, allowEditing: false };

    // Check Name Change
    const [nameErrorLog, setNameErrorLog] = useState('')

    // Current bot status
    const [botStatus, setBotStatus] = useState('');

    // Button control
    const [isDisabled, setIsDisabled] = useState(false);

    const navitage = useNavigate()

    useEffect(() => {
        // Hide sidebar
        setActiveMenu(false)

        getBotData()
    }, []);

    //
    function dashboardButton() {
        navitage('/')
    }

    // Get Bot Data
    async function getBotData() {
        const querySnapshot = await getDocs(collection(db, "Users", email, 'Bot'));
        let allBotData = []

        querySnapshot.forEach((doc) => {
            const botData = doc.data()
            allBotData.push(botData)
        });

        setAllBotData(allBotData)
    }

    // Change Bot Name
    async function changeBotName() {
        let name = false
        const querySnapshot = await getDocs(collection(db, "Users", email, 'Bot'))

        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            if (doc.id === nameData.nameChange) {
                name = true
            }
        })
        
        let oldOrdersData = []
        let oldBotData = {}
        
        if (botData.name !== '' && botData.name !== nameData.nameChange) {

            if (name !== true) {
                const data = allBotData

                for (let i = 0; i < data.length; i++) {

                    if (botData.name === data[i].name) {
                        
                        //* Change Bot Name
                        // 1-Get Old Orders Data
                        const botNameDatabase = doc(db, "Users", email, 'Bot', botData.name)
                        const docSnapName = await getDoc(botNameDatabase)
                        if (docSnapName.exists()) {
                            const dataName = docSnapName.data()
                            oldBotData = dataName
                        } else {
                            // doc.data() will be undefined in this case
                            console.log("No such document!");
                        }
                        // 2-Set New Order Data 
                        const botNameDatabase2 = doc(db, "Users", email, 'Bot', nameData.nameChange)
                        console.log(oldBotData);
                        console.log(oldBotData.name);
                        
                        await setDoc(botNameDatabase2, {
                            name: nameData.nameChange,
                            bot: oldBotData.bot,
                            api_key: oldBotData.api_key,
                            secret_key: oldBotData.secret_key,
                            leverage: oldBotData.leverage,
                            limitOrders: oldBotData.limitOrders,
                            currentOrder: oldBotData.currentOrder,
                            longNoStopLoss: oldBotData.longNoStopLoss,
                            shortNoStopLoss: oldBotData.shortNoStopLoss,
                            firstOrderDelay: oldBotData.firstOrderDelay,
                            orderDelayBetween: oldBotData.orderDelayBetween,
                            status: oldBotData.status,
                            token:  oldBotData.token,
                            lineToken: oldBotData.lineToken,
                            lastIPLogin: oldBotData.lastIPLogin,
                            lastUpdate: oldBotData.lastUpdate,
                            totalBalances: oldBotData.totalBalances,
                            lineLang: oldBotData.lineLang
                        })

                        // 3-Delete Old Orders Data
                        await deleteDoc(doc(db, "Users", email, 'Bot', botData.name));

                        //--------------------------------------------------------------------------------------

                        //* Change Bot Orders Name
                        // 1-Get Old Orders Data
                        const orderDatabase = doc(db, "Users", email, 'Orders', botData.name)
                        const docSnap2 = await getDoc(orderDatabase)
                        if (docSnap2.exists()) {
                            const data3 = docSnap2.data()
                            oldOrdersData = data3.data
                        } else {
                            // doc.data() will be undefined in this case
                            console.log("No such document!");
                        }
                        // 2-Set New Order Data 
                        const orderDatabase2 = doc(db, "Users", email, 'Orders', nameData.nameChange)
                        await setDoc(orderDatabase2, {
                            data: oldOrdersData
                        })

                        // 3-Delete Old Orders Data
                        await deleteDoc(doc(db, "Users", email, 'Orders', botData.name));

                        //--------------------------------------------------------------------------------------

                        //* Change Bot PNL Name
                        // 1-Get Old PNL Data
                        let allPNLData = 0
                        let listPNLData = []
                        let pnlHistoryData = []

                        const pnlDatabase = doc(db, "Users", email, 'PNL', botData.name)
                        const docSnap3 = await getDoc(pnlDatabase)
                        if (docSnap3.exists()) {
                            const data4 = docSnap3.data()
                            allPNLData = data4.allPNL
                            listPNLData = data4.listPNL
                            pnlHistoryData = data4.pnlHistory
                        } else {
                            // doc.data() will be undefined in this case
                            console.log("No such document!");
                        }

                        // 2-Set New PNL Data 
                        const pnlDatabase2 = doc(db, "Users", email, 'PNL', nameData.nameChange)
                        await setDoc(pnlDatabase2, {
                            allPNL: allPNLData,
                            listPNL: listPNLData,
                            pnlHistory: pnlHistoryData
                        })

                        // 3-Delete Old PNL Data
                        await deleteDoc(doc(db, "Users", email, 'PNL', botData.name));

                        //--------------------------------------------------------------------------------------

                        //* Change Bot Logs Name
                        // 1-Get Old Logs Data
                        let errorData = []

                        const logsDatabase = doc(db, "Users", email, 'Logs', botData.name)
                        const docSnap4 = await getDoc(logsDatabase)
                        if (docSnap4.exists()) {
                            const data5 = docSnap4.data()
                            errorData = data5.error
                            console.log(data5.error);
                        } else {
                            // doc.data() will be undefined in this case
                            console.log("No such document!");
                        }
                    
                    
                        // 2-Set New PNL Data 
                        const logsDatabase2 = doc(db, "Users", email, 'Logs', nameData.nameChange)
                        await setDoc(logsDatabase2, {
                            error: errorData
                        })

                        // 3-Delete Old PNL Data
                        await deleteDoc(doc(db, "Users", email, 'Logs', botData.name));
                    

                        //--------------------------------------------------------------------------------------
                
                        //* Change Bot UI Name
                        // 1-Get Old UI Data
                        let currentOrdersData = []
                        let openOrdersData = []
                        let updateUIData = []

                        const uiDatabase = doc(db, "Users", email, 'UI', botData.name)
                        const docSnap5 = await getDoc(uiDatabase)
                        if (docSnap5.exists()) {
                            const data6 = docSnap5.data()
                            currentOrdersData = data6.currentOrders
                            openOrdersData = data6.openOrders
                            updateUIData = data6.updateUI
                        } else {
                            // doc.data() will be undefined in this case
                            console.log("No such document!");
                        }

                        // 2-Set New PNL Data 
                        const uiDatabase2 = doc(db, "Users", email, 'UI', nameData.nameChange)
                        await setDoc(uiDatabase2, {
                            currentOrders: currentOrdersData,
                            openOrders: openOrdersData,
                            updateUI: updateUIData
                        })

                        // 3-Delete Old PNL Data
                        await deleteDoc(doc(db, "Users", email, 'UI', botData.name));

                    }

                }

                setErrorLog('')

                // Success
                setBotData({'name': nameData.nameChange})

                window.location.reload()

            } else {
                setNameErrorLog('This name is already taken')
            }

        } else {
            setErrorLog('Please select a bot first')
        }
    }

    const contextMenuItems = [
        'AutoFit',
        'AutoFitAll',
        'SortAscending',
        'SortDescending',
        'Copy',
        'Edit',
        'Delete',
        'Save',
        'Cancel',
        'PdfExport',
        'ExcelExport',
        'CsvExport',
        'FirstPage',
        'PrevPage',
        'LastPage',
        'NextPage',
    ]

    const botGrid = [
        {
            field: 'name',
            headerText: 'Bot Name',
            width: '300',
            textAlign: 'Left',
        },
        {
            field: 'status',
            headerText: 'Status',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'leverage',
            headerText: 'Leverage',
            width: '120',
            textAlign: 'Left',
        },
        {
            field: 'limitOrders',
            headerText: 'Limit Orders',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'currentOrder',
            headerText: 'Current Order',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'longNoStopLoss',
            headerText: 'Long No Stop Loss',
            width: '180',
            textAlign: 'Left',
        },
        {
            field: 'shortNoStopLoss',
            headerText: 'Short No Stop Loss',
            width: '180',
            textAlign: 'Left',
        },
        {
            field: 'firstOrderDelay',
            headerText: 'First Order Delay',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'orderDelayBetween',
            headerText: 'Order Delay Between',
            width: '180',
            textAlign: 'Left',
        },
        {
            field: 'api_key',
            headerText: 'API Key',
            width: '600',
            textAlign: 'Left',
        },
        {
            field: 'secret_key',
            headerText: 'Secret Key',
            width: '600',
            textAlign: 'Left',
        },
        {
            field: 'lineToken',
            headerText: 'LINE Token',
            width: '300',
            textAlign: 'Left',
        },
        {
            field: 'token',
            headerText: 'Token',
            width: '300',
            textAlign: 'Left',
        },
        {
            field: 'lastIPLogin',
            headerText: 'Last IP Login',
            width: '300',
            textAlign: 'Left',
        },
        {
            field: 'lastUpdate',
            headerText: 'Last Update',
            width: '300',
            textAlign: 'Left',
        },
    ]

    const ordersGrid = [
        {
            field: 'numOrder',
            headerText: 'Order No.',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'amount',
            headerText: 'Amount',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'takeProfit',
            headerText: 'Take Profit',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'stopLoss',
            headerText: 'Stop Loss',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'symbol',
            headerText: 'Symbol',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'signal',
            headerText: 'Signal',
            width: '200',
            textAlign: 'Left',
        },
    ]

    //
    const [botData, setBotData] = useState({
        name: '',
        bot: 'BinanceFutures',
        api_key: '',
        secret_key: '',
        leverage: 5,
        limitOrders: 1,
        currentOrder: 1,
        longNoStopLoss: 'No',
        shortNoStopLoss: 'No',
        firstOrderDelay: 10,
        orderDelayBetween: 10,
        status: 'New',
        lineToken: '',
        lastIPLogin: '',
        lastUpdate: ''
    })

    const [nameData, setNameData] = useState({
        nameChange: '',
    })

    const [orderSetting, setOrderSetting] = useState({
        numOrder: 1,
        amount: 10,
        takeProfit: 5,
        stopLoss: 5,
        symbol: '',
        signal: ''
    })

    const [log, setLog] = useState({
        success: '',
        error: ''
    })

    const [log2, setLog2] = useState('')

    const allContracts = ["ALL", "1000LUNCUSDT", "1000SHIBUSDT", "1000XECUSDT", "1INCHUSDT", "AAVEUSDT", "ADAUSDT", "ALGOUSDT", "ALICEUSDT", "ALPHAUSDT", "ANKRUSDT", "ANTUSDT", "APEUSDT", "API3USDT", "APTUSDT", "ARPAUSDT", "ARUSDT", "ATAUSDT", "ATOMUSDT", "AUDIOUSDT", "AVAXUSDT", "AXSUSDT", "BAKEUSDT", "BALUSDT", "BANDUSDT", "BATUSDT", "BCHUSDT", "BELUSDT", "BLUEBIRDUSDT", "BLZUSDT", "BNBUSDT", "BNXUSDT", "BTCDOMUSDT", "BTCSTUSDT", "BTCUSDT", "BTSUSDT", "C98USDT", "CELOUSDT", "CELRUSDT", "CHRUSDT", "CHZUSDT", "COMPUSDT", "COTIUSDT", "CRVUSDT", "CTKUSDT", "CTSIUSDT", "CVCUSDT", "CVXUSDT", "DARUSDT", "DASHUSDT", "DEFIUSDT", "DENTUSDT", "DGBUSDT", "DOGEUSDT", "DOTUSDT", "DUSKUSDT", "DYDXUSDT", "EGLDUSDT", "ENJUSDT", "ENSUSDT", "EOSUSDT", "ETCUSDT", "ETHUSDT", "FILUSDT", "FLMUSDT", "FLOWUSDT", "FOOTBALLUSDT", "FTMUSDT", "FTTUSDT", "GALAUSDT", "GALUSDT", "GMTUSDT", "GRTUSDT", "GTCUSDT", "HBARUSDT", "HNTUSDT", "HOTUSDT", "ICPUSDT", "ICXUSDT", "IMXUSDT", "INJUSDT", "IOSTUSDT", "IOTAUSDT", "IOTXUSDT", "JASMYUSDT", "KAVAUSDT", "KLAYUSDT", "KNCUSDT", "KSMUSDT", "LDOUSDT", "LINAUSDT", "LINKUSDT", "LITUSDT", "LPTUSDT", "LRCUSDT", "LTCUSDT", "LUNA2USDT", "MANAUSDT", "MASKUSDT", "MATICUSDT", "MKRUSDT", "MTLUSDT", "NEARUSDT", "NEOUSDT", "NKNUSDT", "OCEANUSDT", "OGNUSDT", "OMGUSDT", "ONEUSDT", "ONTUSDT", "OPUSDT", "PEOPLEUSDT", "QNTUSDT", "QTUMUSDT", "RAYUSDT", "REEFUSDT", "RENUSDT", "RLCUSDT", "ROSEUSDT", "RSRUSDT", "RUNEUSDT", "RVNUSDT", "SANDUSDT", "SCUSDT", "SFPUSDT", "SKLUSDT", "SNXUSDT", "SOLUSDT", "SPELLUSDT", "SRMUSDT", "STGUSDT", "STMXUSDT", "STORJUSDT", "SUSHIUSDT", "SXPUSDT", "THETAUSDT", "TLMUSDT", "TOMOUSDT", "TRBUSDT", "TRXUSDT", "UNFIUSDT", "UNIUSDT", "VETUSDT", "WAVESUSDT", "WOOUSDT", "XEMUSDT", "XLMUSDT", "XMRUSDT", "XRPUSDT", "XTZUSDT", "YFIUSDT", "ZECUSDT", "ZENUSDT", "ZILUSDT", "ZRXUSDT"]
    const allSignals = ['TradingView', 'Technical Trader', 'Random', 'Long', 'Short', 'Follow Trend', 'Opposite Trend', 'D-Days Short O-Days Long', 'D-Days Long O-Days Short']
    const options = ['Yes', 'No']

    //
    function setErrorLog(e) {
        const newdata = { ...log }
        newdata['error'] = e
        setLog(newdata)
    }

    //
    function handleBotName(e){
        
        const newdata = { ...botData }
        newdata['name'] = e.target.value
        setBotData(newdata)

        const newdata2 = { ...botData }
        newdata2['nameChange'] = e.target.value
        setNameData(newdata2)

        getStatusBot(e.target.value)
        getOrdersBot(e.target.value)

    }

    //
    function handleBotSettings(e){
        const newdata = { ...botData }
        newdata[e.target.id] = e.target.value
        setBotData(newdata)
    }

    //
    function handleBotSettingsToNumber(e){
        const newdata = { ...botData }
        let value = Number(e.target.value)

        newdata[e.target.id] = value
        setBotData(newdata)
    }

    //
    function handleBotChangeName(e){
        const newdata = { ...nameData }
        newdata['nameChange'] = e.target.value
        setNameData(newdata)
    }

    // Get Orders Bot
    async function getOrdersBot(name) {
        const dataBase2 = doc(db, "Users", email, 'Orders', name)
        const docSnap = await getDoc(dataBase2)

        if (docSnap.exists()) {
            const data = docSnap.data()
            const data2 = data.data

            setOrdersData(data2)
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }

    }

    // Setting Order
    function handleOrdersSettings(e){
        const newdata = { ...orderSetting }
        let value = Number(e.target.value)

        newdata[e.target.id] = value
        setOrderSetting(newdata)

    }

    
    // Setting Contract
    function handleOrdersContractSettings(e){
        const newdata = { ...orderSetting }
        newdata['symbol'] = e.target.value
        setOrderSetting(newdata)
    }

    // Setting Signal
    function handleOrdersSignalSettings(e){
        const newdata = { ...orderSetting }
        newdata['signal'] = e.target.value
        setOrderSetting(newdata)

    }

    // Setting Long No Stop Loss
    function handleLongNoStopLossSettings(e){
        const newdata = { ...botData }
        newdata['longNoStopLoss'] = e.target.value
        setBotData(newdata)

    }

    //* Setting Short No Stop Loss
    function handleShortNoStopLossSettings(e){
        const newdata = { ...botData }
        newdata['shortNoStopLoss'] = e.target.value
        setBotData(newdata)

    }

    // Set Trade Button
    async function setTrade() {
        
        if (botData.name !== '') {
            const botDatabase = doc(db, "Users", email, 'Bot', botData.name)

            await updateDoc(botDatabase, {
                api_key: botData.api_key,
                secret_key: botData.secret_key,
                leverage: botData.leverage,
                longNoStopLoss: botData.longNoStopLoss,
                shortNoStopLoss: botData.shortNoStopLoss,
                firstOrderDelay: botData.firstOrderDelay,
                orderDelayBetween: botData.orderDelayBetween,
                lineToken: botData.lineToken,
            })

            setErrorLog('')
            getBotData()

        } else {
            setErrorLog('Please select a bot first')
        }
    }

    // Add Order Button
    function addOrder() {
        const numOrder = orderSetting.numOrder
        
        if (botData.name === '') {
            setErrorLog('Please add bot first')
        } else {
            
            if (numOrder >= 1) {
                updateBotOrder(numOrder)
            } else {
                setErrorLog('Order number must be greater than or equal to 1')
            }

      
        }
    }

    // Update Bot Orders
    async function updateBotOrder(e) {
        const dataBase2 = doc(db, "Users", email, 'Orders', botData.name)
        
        const docSnap = await getDoc(dataBase2)

        const botNumber = e
        
        let oldData = []

        if (docSnap.exists()) {
            const data = docSnap.data()
            const data2 = data.data

            for (let i = 0; i < data2.length; i++) {

                if (botNumber === data2[i].numOrder) {
                    oldData = data2[i]

                    await updateDoc(dataBase2, {
                        data: arrayRemove(oldData)
                    })
        
                    await updateDoc(dataBase2, {
                        data: arrayUnion(orderSetting)
                    })

                    setErrorLog('')

                } else {
                    await updateDoc(dataBase2, {
                        data: arrayUnion(orderSetting)
                    })

                    setErrorLog('')
                }
            }

            getOrdersBot(botData.name)

        } else {
            // doc.data() will be undefined in this case
            setErrorLog('No such document!')
        }
    }

    // Remove Order Button
    function removeOrder() {
        const numOrder = orderSetting.numOrder
        
        if (botData.name === '') {
            setErrorLog('Please add bot first')
        } else {
            
            if (numOrder >= 1) {
                removeBotOrder(numOrder)
            } else {
                setErrorLog('Order number must be greater than or equal to 1')
            }
        }
    }

    // Remove Bot Order
    async function removeBotOrder(e) {
        const dataBase2 = doc(db, "Users", email, 'Orders', botData.name)
        
        const docSnap = await getDoc(dataBase2)

        const botNumber = e
        
        let oldData = []

        if (docSnap.exists()) {
            const data = docSnap.data()
            const data2 = data.data

            if (data2.length > 1) {
                for (let i = 0; i < data2.length; i++) {

                    if (botNumber === data2[i].numOrder) {
                        oldData = data2[i]
                        await updateDoc(dataBase2, {
                            data: arrayRemove(oldData)
                        })

                    } else {
                        setErrorLog('')
                    }
                }
            } else {
                setErrorLog('Order bot must have at least 1')
            }

            getOrdersBot(botData.name)
            
        } else {
            // doc.data() will be undefined in this case
            setErrorLog('No such document!')
        }
    }

    // Start Bot Button
    async function startBot() {
        
        if (botData.name !== '') {
            setLog2('Bot Start')
            const data = allBotData
            let botName = ''

            for (let i = 0; i < data.length; i++) {
                
                if (botData.name === data[i].name) {
                    botName = data[i].name
                    const botDatabase = doc(db, "Users", email, 'Bot', data[i].name)

                    await updateDoc(botDatabase, {
                        status: 'Start Web'
                    })
                }

            }

            // 1-Get Current PNL Data
            const pnlDatabase = doc(db, "Users", email, 'PNL', botData.name)
            await updateDoc(pnlDatabase, {
                allPNL: 0
            })

            setErrorLog('')
            getBotData()
            getStatusBot(botName)

        } else {
            setErrorLog('Please select a bot first')
        }
    }

    // Stop Bot Button
    async function stopBot() {
        if (botData.name !== '') {
            setLog2('Bot Stop')
            const data = allBotData
            let botName = ''

            for (let i = 0; i < data.length; i++) {

                if (botData.name === data[i].name) {
                    botName = data[i].name
                    const botDatabase = doc(db, "Users", email, 'Bot', data[i].name)

                    await updateDoc(botDatabase, {
                        status: 'Stop Web'
                    })
                }
            }

            setErrorLog('')
            getBotData()
            getStatusBot(botName)

        } else {
            setErrorLog('Please select a bot first')
        }
    }

    // Download FuturesX Bot
    function downloadBot() {
        window.open('https://drive.google.com/file/d/1o-Uphq5dd6XgQc7_iGLgcJR9vEL2oaxq/view?usp=share_link')
    }

    // Get status bot
    async function getStatusBot(name) {
        
        const allBotDataBase = doc(db, "Users", email, 'Bot', name)
        const docSnap = await getDoc(allBotDataBase)

        if (docSnap.exists()) {
            const data = docSnap.data()

            setBotStatus(data.status)

            if (data.status === 'Start Web' || data.status === 'Start Client') {
                setIsDisabled(true)
            } else {
                setIsDisabled(false)
            }

            getOrdersBot(name)
            getBotUiRealtimeData(name)
            getBotLogsRealtimeData(name)

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }

    //* Get Bot UI Realtime Data
    async function getBotUiRealtimeData(name) {

        onSnapshot(doc(db, "Users", email, 'UI', name), (snapShot) => {
            
            const data = snapShot.data()
            
            // Update UI
            const data2 = data.updateUI
            setBotUiData([data2])

            // Open Orders
            const data3 = data.openOrders
            setOpenOrdersData(data3)

            // Current Orders
            const data4 = data.currentOrders
            setCurrentOrdersData(data4)
            
            
        }, (error) => {
            console.log(error);
        });
    }

    //* Get Bot Logs Realtime Data
    async function getBotLogsRealtimeData(name) {

        onSnapshot(doc(db, "Users", email, 'Logs', name), (snapShot) => {
            
            const data = snapShot.data()
            
            // Error
            const data2 = data.error
            setErrorLogsData(data2)
            
        }, (error) => {
            console.log(error);
        });
    }

    //*
    const uiGrid = [
        {
            field: 'updateTime',
            headerText: 'Update Time',
            width: '200',
            textAlign: 'Left',
        },
        {
            field: 'unrealized',
            headerText: 'Unrealized',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'roi',
            headerText: 'Roi',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'totalBalance',
            headerText: 'Total Balance',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'availableBalance',
            headerText: 'Available Balance',
            width: '180',
            textAlign: 'Left',
        },
        {
            field: 'usdtBalances',
            headerText: 'USDT Balances',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'lastSymbol',
            headerText: 'Last Symbol',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'currentOrder',
            headerText: 'Current Order',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'lastOrderSide',
            headerText: 'Last Order Side',
            width: '150',
            textAlign: 'Left',
        }
    ]

    //
    const openOrdersGrid = [
        {
            field: 'orderId',
            headerText: 'Order ID',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'symbol',
            headerText: 'Symbol',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'side',
            headerText: 'Side',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'status',
            headerText: 'Status',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'stopPrice',
            headerText: 'Stop Price',
            width: '200',
            textAlign: 'Left',
        },
        {
            field: 'type',
            headerText: 'Type',
            width: '200',
            textAlign: 'Left',
        },
    ]

    //
    const currentOrdersGrid = [
        {
            field: 'Update Time',
            headerText: 'Update Time',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'Symbol',
            headerText: 'Symbol',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'Side',
            headerText: 'Side',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'Qty',
            headerText: 'Qty',
            width: '180',
            textAlign: 'Left',
        },
        {
            field: 'Entry Price',
            headerText: 'Entry Price',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'Mark Price',
            headerText: 'Mark Price',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'Margin',
            headerText: 'Margin',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'Margin Type',
            headerText: 'Margin Type',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'Leverage',
            headerText: 'Leverage',
            width: '200',
            textAlign: 'Left',
        },
        {
            field: 'PNL2',
            headerText: 'PNL',
            width: '200',
            textAlign: 'Left',
        },
    ]

    //
    const errorLogsGrid = [
        {
            field: 'datetime',
            headerText: 'Date Time',
            width: '150',
            textAlign: 'Left',
        },
        {
            field: 'message',
            headerText: 'Message',
            width: '150',
            textAlign: 'Left',
        }
    ]

    console.log(currentOrdersData);


    //---------------------------------------------------------------------------------------------------------------------------------------------------------------------

    return (
    <div>  

        
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
            
            <Button variant="contained" onClick={dashboardButton}>{i18next.t('Dashboard')}</Button> 
            <br />
            <br />
            <Button variant="outlined" onClick={downloadBot}>{i18next.t('Download FuturesX Bot')}</Button><br /><br />
            <Header category="BinanceFutures" title={i18next.t('เลือกบอท')} />
            <Header category={i18next.t('All Bot')}/>

            <GridComponent
                id="gridcomp"
                dataSource={allBotData}
                allowPaging
                allowSorting
                allowExcelExport
                allowPdfExport
                contextMenuItems={contextMenuItems}
                editSettings={editing}
            >
                <ColumnsDirective>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                {botGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
                </ColumnsDirective>
                <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport]} />
            </GridComponent>

            <br />
            <br />
            
            <Box sx={{ maxWidth: 200 }}>
                <FormControl fullWidth>
                    <InputLabel id="demo-select-small">{i18next.t('Bot')}</InputLabel>
                    <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        label="Bot"
                        onChange={(e) => handleBotName(e)} value={botData.name}
                    >
                        {allBotData.map(p => (
                            <MenuItem key={"name-shared" + p.name} value={p.name}>{p.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <br />
            <Header category={botData.name}/>

            {/* Change Name */}
            <TextField
                    id="nameChange"
                    label={i18next.t('Change Name')}
                    type="text"
                    disabled={isDisabled}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    onChange={(e) => handleBotChangeName(e)} value={nameData.nameChange}
            />
            <br />
            <br />
            <Button variant="outlined" color='error' disabled={isDisabled} onClick={changeBotName}>{i18next.t('Change Name')}</Button>
            <br />
            <br />

            {/* Bot UI */}
            <div>
                <Header title={i18next.t('Bot UI')} />

                {botUiData[0] && (

                <div className="flex m-3 flex-wrap justify-start gap-1 items-center">

                    <div className="bg-black h-44 dark:text-gray-200 md:w-56  p-4 mb-[20px] rounded-[30px] ">
                        <p className="mt-3">
                            <span className="text-white text-lg font-semibold">Unrealized</span>
                        </p>

                        <p className="text-white text-2xl mt-1">{botUiData[0].unrealized}</p><br />
                        <p className="text-sm text-gray-400  mt-1">{botUiData[0].updateTime}</p>
                    </div>
            
                    <div className="bg-black h-44 dark:text-gray-200 md:w-56  p-4 mb-[20px] rounded-[30px] ">
                        <p className="mt-3">
                            <span className="text-white text-lg font-semibold">ROI</span>
                        </p>

                        <p className="text-white text-2xl mt-1">{botUiData[0].roi}</p><br />
                        <p className="text-sm text-gray-400  mt-1">{botUiData[0].updateTime}</p>
                    </div>

                    <div className="bg-black h-44 dark:text-gray-200 md:w-56  p-4 mb-[20px] rounded-[30px] ">
                        <p className="mt-3">
                            <span className="text-white text-lg font-semibold">Total Balance</span>
                        </p>

                        <p className="text-white text-2xl mt-1">{botUiData[0].totalBalance}</p><br />
                        <p className="text-sm text-gray-400  mt-1">{botUiData[0].updateTime}</p>
                    </div>

                    <div className="bg-black h-44 dark:text-gray-200 md:w-56  p-4 mb-[20px] rounded-[30px] ">
                        <p className="mt-3">
                            <span className="text-white text-lg font-semibold">Available Balance</span>
                        </p>

                        <p className="text-white text-2xl mt-1">{botUiData[0].availableBalance}</p><br />
                        <p className="text-sm text-gray-400  mt-1">{botUiData[0].updateTime}</p>
                    </div>

                </div>

                )}

                <GridComponent
                id="gridcomp"
                dataSource={botUiData}
                allowPaging
                allowSorting
                allowExcelExport
                allowPdfExport
                contextMenuItems={contextMenuItems}
                editSettings={editing}
                >
                    <ColumnsDirective>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    {uiGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
                    </ColumnsDirective>
                    <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport]} />
                </GridComponent>
                <br />
                <Header category={i18next.t('Open Orders')}/>

                {openOrdersData[0] && (

                <div className="flex m-3 flex-wrap justify-start gap-1 items-center">

                    <div className="bg-black h-35 dark:text-gray-200 md:w-56  p-4 mb-[20px] rounded-[30px] ">
                        <p className="mt-3">
                            <span className="text-white text-lg font-semibold">Pair</span>
                        </p>

                        <p className="text-white text-2xl mt-1">{openOrdersData[0].symbol}</p><br />
                    </div>

                    <div className="bg-black h-35 dark:text-gray-200 md:w-56  p-4 mb-[20px] rounded-[30px] ">
                        <p className="mt-3">
                            <span className="text-white text-lg font-semibold">Side</span>
                        </p>

                        <p className="text-white text-2xl mt-1">{openOrdersData[0].side}</p><br />
                    </div>

                    <div className="bg-black h-35 dark:text-gray-200 md:w-56  p-4 mb-[20px] rounded-[30px] ">
                        <p className="mt-3">
                            <span className="text-white text-lg font-semibold">Stop Price</span>
                        </p>

                        <p className="text-white text-2xl mt-1">{openOrdersData[0].stopPrice}</p><br />
                    </div>

                </div>

                )}

                <GridComponent
                id="gridcomp"
                dataSource={openOrdersData}
                allowPaging
                allowSorting
                allowExcelExport
                allowPdfExport
                contextMenuItems={contextMenuItems}
                editSettings={editing}
                >
                    <ColumnsDirective>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    {openOrdersGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
                    </ColumnsDirective>
                    <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport]} />
                </GridComponent>
                <br />
                <Header category={i18next.t('Current Orders')}/>

                {currentOrdersData[0] && (

                <div className="flex m-3 flex-wrap justify-start gap-1 items-center">

                    <div className="bg-black h-44 dark:text-gray-200 md:w-56  p-4 mb-[20px] rounded-[30px] ">
                        <p className="mt-3">
                            <span className="text-white text-lg font-semibold">Symbol</span>
                        </p>

                        <p className="text-white text-2xl mt-1">{currentOrdersData[0].Symbol}</p><br />
                        <p className="text-sm text-gray-400  mt-1">{currentOrdersData[0]["Update Time"]}</p>
                    </div>

                    <div className="bg-black h-44 dark:text-gray-200 md:w-56  p-4 mb-[20px] rounded-[30px] ">
                        <p className="mt-3">
                            <span className="text-white text-lg font-semibold">Side</span>
                        </p>

                        <p className="text-white text-2xl mt-1">{currentOrdersData[0].Side}</p><br />
                        <p className="text-sm text-gray-400  mt-1">{currentOrdersData[0]["Update Time"]}</p>
                    </div>

                    <div className="bg-black h-44 dark:text-gray-200 md:w-56  p-4 mb-[20px] rounded-[30px] ">
                        <p className="mt-3">
                            <span className="text-white text-lg font-semibold">Entry Price</span>
                        </p>

                        <p className="text-white text-2xl mt-1">{currentOrdersData[0]["Entry Price"]}</p><br />
                        <p className="text-sm text-gray-400  mt-1">{currentOrdersData[0]["Update Time"]}</p>
                    </div>

                    <div className="bg-black h-44 dark:text-gray-200 md:w-56  p-4 mb-[20px] rounded-[30px] ">
                        <p className="mt-3">
                            <span className="text-white text-lg font-semibold">Market Price</span>
                        </p>

                        <p className="text-white text-2xl mt-1">{currentOrdersData[0]["Mark Price"]}</p><br />
                        <p className="text-sm text-gray-400  mt-1">{currentOrdersData[0]["Update Time"]}</p>
                    </div>

                    <div className="bg-black h-44 dark:text-gray-200 md:w-56  p-4 mb-[20px] rounded-[30px] ">
                        <p className="mt-3">
                            <span className="text-white text-lg font-semibold">Margin</span>
                        </p>

                        <p className="text-white text-2xl mt-1">{currentOrdersData[0]["Margin"]}</p><br />
                        <p className="text-sm text-gray-400  mt-1">{currentOrdersData[0]["Update Time"]}</p>
                    </div>

                    <div className="bg-black h-44 dark:text-gray-200 md:w-56  p-4 mb-[20px] rounded-[30px] ">
                        <p className="mt-3">
                            <span className="text-white text-lg font-semibold">Leverage</span>
                        </p>

                        <p className="text-white text-2xl mt-1">{currentOrdersData[0]["Leverage"]}</p><br />
                        <p className="text-sm text-gray-400  mt-1">{currentOrdersData[0]["Update Time"]}</p>
                    </div>

                    <div className="bg-black h-44 dark:text-gray-200 md:w-56  p-4 mb-[20px] rounded-[30px] ">
                        <p className="mt-3">
                            <span className="text-white text-lg font-semibold">PNL</span>
                        </p>

                        <p className="text-white text-2xl mt-1">{currentOrdersData[0]["PNL2"]}</p><br />
                        <p className="text-sm text-gray-400  mt-1">{currentOrdersData[0]["Update Time"]}</p>
                    </div>

                </div>

                )}

                <GridComponent
                id="gridcomp"
                dataSource={currentOrdersData}
                allowPaging
                allowSorting
                allowExcelExport
                allowPdfExport
                contextMenuItems={contextMenuItems}
                editSettings={editing}
                >
                    <ColumnsDirective>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    {currentOrdersGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
                    </ColumnsDirective>
                    <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport]} />
                </GridComponent>

                <br />

                <Header category={i18next.t('Error Logs')}/>
                <GridComponent
                id="gridcomp"
                dataSource={errorLogsData}
                allowPaging
                allowSorting
                allowExcelExport
                allowPdfExport
                contextMenuItems={contextMenuItems}
                editSettings={editing}
                >
                    <ColumnsDirective>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    {errorLogsGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
                    </ColumnsDirective>
                    <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport]} />
                </GridComponent>

            </div>

            <br />

            <p>{nameErrorLog}</p>

            <br />
            <br />

            <div>
                <Header title={i18next.t('Trade Settings')} />

                <TextField
                        id="api_key"
                        label={i18next.t('API Key')}
                        type="text"
                        disabled={isDisabled}
                        sx={{ width: '35ch' }}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        onChange={(e) => handleBotSettings(e)} value={botData.api_key}
                />
                <br />
                <br />
                <TextField
                        id="secret_key"
                        label={i18next.t('Secret Key')}
                        type="text"
                        disabled={isDisabled}
                        sx={{ width: '35ch' }}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        onChange={(e) => handleBotSettings(e)} value={botData.secret_key}
                />
                <br />
                <br />

                <TextField
                        id="lineToken"
                        label={i18next.t('LINE Token')}
                        type="text"
                        disabled={isDisabled}
                        sx={{ width: '30ch' }}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        onChange={(e) => handleBotSettings(e)} value={botData.lineToken}
                />
                <br />
                <br />

                <TextField
                        id="leverage"
                        label={i18next.t('Leverage')}
                        type="number"
                        disabled={isDisabled}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        onChange={(e) => handleBotSettingsToNumber(e)} value={botData.leverage}
                />
                <br />
                <br />

                <Box sx={{ maxWidth: 200 }}>
                    <h1>{i18next.t('Long No Stop Loss')}</h1>
                    <br />
                    <FormControl fullWidth>
                        <InputLabel id="longNoStopLoss"></InputLabel>
                        <Select
                            labelId="longNoStopLoss"
                            id="longNoStopLoss"
                            label="longNoStopLoss"
                            disabled={isDisabled}
                            onChange={(e) => handleLongNoStopLossSettings(e)} value={botData.longNoStopLoss}
                        >
                            {options.map(p => (
                                <MenuItem key={"name-shared" + p} value={p}>{p}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <br />

                <Box sx={{ maxWidth: 200 }}>
                    <h1>{i18next.t('Short No Stop Loss')}</h1>
                    <br />
                    <FormControl fullWidth>
                        <InputLabel id="shortNoStopLoss"></InputLabel>
                        <Select
                            labelId="shortNoStopLoss"
                            id="shortNoStopLoss"
                            label="shortNoStopLoss"
                            disabled={isDisabled}
                            onChange={(e) => handleShortNoStopLossSettings(e)} value={botData.shortNoStopLoss}
                        >
                            {options.map(p => (
                                <MenuItem key={"name-shared" + p} value={p}>{p}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <br />
                <Button variant="outlined" disabled={isDisabled} onClick={setTrade}>{i18next.t('Update Trade Settings')}</Button>

            </div>

        </div>

        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
            <Header category={botData.name} title={i18next.t('Bot Orders Settings')} />
            <TextField
                    id="numOrder"
                    label={i18next.t('Order Number')}
                    type="number"
                    disabled={isDisabled}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    onChange={(e) => handleOrdersSettings(e)} value={orderSetting.numOrder}
            />
            <br />
            <br />
            <TextField
                    id="amount"
                    label={i18next.t('Amount (USDT)')}
                    type="number"
                    disabled={isDisabled}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    onChange={(e) => handleOrdersSettings(e)} value={orderSetting.amount}
            />
            <br />
            <br />
            <TextField
                    id="takeProfit"
                    label={i18next.t('Take Profit')}
                    type="number"
                    disabled={isDisabled}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    onChange={(e) => handleOrdersSettings(e)} value={orderSetting.takeProfit}
            />
            <br />
            <br />
            <TextField
                    id="stopLoss"
                    label={i18next.t('Stop Loss')}
                    type="number"
                    disabled={isDisabled}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    onChange={(e) => handleOrdersSettings(e)} value={orderSetting.stopLoss}
            />
            <br />
            <br />

            <Box sx={{ maxWidth: 200 }}>
                <FormControl fullWidth>
                    <InputLabel id="symbol">{i18next.t('Symbol')}</InputLabel>
                    <Select
                        labelId="symbol"
                        id="symbol"
                        label="symbol"
                        disabled={isDisabled}
                        onChange={(e) => handleOrdersContractSettings(e)} value={orderSetting.symbol}
                    >
                        {allContracts.map(p => (
                            <MenuItem key={"name-shared" + p} value={p}>{p}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <br />

            <Box sx={{ maxWidth: 200 }}>
                <FormControl fullWidth>
                    <InputLabel id="signal">{i18next.t('Signal')}</InputLabel>
                    <Select
                        labelId="signal"
                        id="signal"
                        label="signal"
                        disabled={isDisabled}
                        onChange={(e) => handleOrdersSignalSettings(e)} value={orderSetting.signal}
                    >
                        {allSignals.map(p => (
                            <MenuItem key={"name-shared" + p} value={p}>{p}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <br />

            {/* Description */}
            <p>TradingView - {i18next.t('ใช้สัญญาณการเทรดจากเว็บไซต์ TradingView')}</p>
            <p>Technical Trader - {i18next.t('ใช้สัญญาณการเทรดจาก Technical Trader')}</p>
            <p>Random - {i18next.t('สุ่มเทรดว่าจะ BUY หรือ SELL')}</p>
            <p>Long - {i18next.t('เทรด BUY เท่านั้น')}</p>
            <p>Short - {i18next.t('เทรด SELL เท่านั้น')}</p>
            <p>Follow Trend - {i18next.t('เทรดตามเทรนด์ปัจจุบัน')}</p>
            <p>Opposite Trend - {i18next.t('เทรดสวนเทรนด์ปัจจุบัน')}</p>
            <p>D-Days Short O-Days Long - {i18next.t('เทรดสลับวัน วันคู่เทรด SELL วันคี่เทรด BUY')}</p>
            <p>D-Days Long O-Days Short - {i18next.t('เทรดสลับวัน วันคู่เทรด BUY วันคี่เทรด SELL')}</p>

            <br />
            <Button variant="outlined" color="success" disabled={isDisabled} onClick={addOrder}>{i18next.t('Add Order')}</Button> <Button variant="outlined" color='error' disabled={isDisabled} onClick={removeOrder}>{i18next.t('Remove Order')}</Button>
            <br />
            <br />
            <p>{log.error}</p>
            <br />
            <br />
            <GridComponent
                id="gridcomp"
                dataSource={ordersData}
                allowPaging
                allowSorting
                allowExcelExport
                allowPdfExport
                contextMenuItems={contextMenuItems}
                editSettings={editing}
            >
                <ColumnsDirective>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                {ordersGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
                </ColumnsDirective>
                <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport]} />
            </GridComponent>
            <br />
            <br />
            <Header category={botData.name}/>
            <p>Status: {botStatus}</p>
            <br />
            <br />
            <Button variant="contained" color="success" disabled={isDisabled} onClick={startBot}>{i18next.t('Start Trade')}</Button> <Button variant="contained" color='error' onClick={stopBot}>{i18next.t('Stop Trade')}</Button>
            <br />
            <br />
            <p>{log2}</p>
        </div>

    </div>
  )
}

export default FuturesX