import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
      "ขั้นตอนที่ 1 ตั้งค่าการเทรด": "Step 1 Set up trade",
      "การตั้งค่า": "Settings",
      "ขั้นตอนที่ 2 เลือกสัญญาณการเทรด": "Step 2 Choose a trading signal",
      "ขั้นตอนที่ 3 ตัวเลือกอื่นๆ": "Step 3 Other Options",
      "สมัครสมาชิก": "Register",
      "เข้าสู่ระบบ": "Login",
      "เครดิต": "Credit",
      "เติมเครดิต": "Top Up Credit",
      "โปรไฟล์ผู้ใช้": "User Profile",
      "ลงชื่อออก": "Logout",
      "จัดการผู้ใช้": "User Management",
      "ฐานข้อมูลผู้ใช้": "User Database",
      "แอดมิน": "Administrator",
      "แดชบอร์ด": "Dashboard",
      "ตะกร้าสินค้า": "Shopping Cart",
      "ซื้อสินค้า": "BUY Product",
      "จำนวนทั้งหมด": "Total Pieces",
      "จำนวนเครดิตที่ใช้": "Total Credit",
      "เครดิตของคุณ": "Your Credit",
      "PNL ทั้งหมด": "Total PNL",
      "กำไร": "Profit",
      "ขาดทุน": "Loss",
      "ประวัติใช้เครดิต": "Credit History",
      "PNL ภาพรวม": "PNL Overview",
      "เติมเครดิตผ่าน USDT Payment": "USDT Payment",
      "ตั้งค่าการเทรด": "Trade Settings",
      "ตั้งค่าบอทออเดอร์": "Bot Orders Settings",
      "บอท": "Bot",
      "เลือกบอท": "Choose a bot",
      "จำนวน (USDT)": "Amount (USDT)",
      "เพิ่มออเดอร์": "Add Order",
      "ลบออเดอร์": "Remove Order",
      "เปลี่ยนชื่อ": "Change Name",
      "ลำดับออเดอร์": "Order Number",
      "ตั้งกำไร (Take Profit)": "Take Profit",
      "ตั้งหยุดขาดทุน (Stop Loss)": "Stop Loss",
      "คู่เทรด": "Symbol",
      "สัญญาณ": "Signal",
      "อัพเดทข้อมูลออเดอร์": "Update Order",
      "ระยะเวลาเปิดไม้แรก": "First Order Delay",
      "ระยะเวลาเปิดไม้ต่อไป": "Order Delay Between",
      "ถ้า Long ไม่ตั้ง Stop Loss": "Long No Stop Loss",
      "ถ้า Short ไม่ตั้ง Stop Loss": "Short No Stop Loss",
      "อัพเดทการตั้งค่าเทรด": "Update Trade Settings",
      "เริ่มเทรด": "Start Trade",
      "หยุดเทรด": "Stop Trade",
      "ดาวน์โหลด FuturesX บอท": "Download FuturesX Bot",
      "เรทการเติมเครดิตผ่าน ETH Payment": "Top UP Credit Rate via ETH Payment",
      "เลือกบอท": "Select Bot",
      "เติมเครดิตผ่าน ETH Payment": "ETH Credit Payment",
      "เครดิตปัจจุบันของคุณ": "Your Credit",
      "ล็อคอินล่าสุด": "Last Login",
      "ภาษา": "Language",
      "อีเมล": "Email",
      "รหัสผ่าน": "Password",
      "ตะกร้า": "Cart",
      "โปรไฟล์": "Profile",
      "ประวัติการเข้าใช้งาน": "Login History",
      "คงเหลือ": "Balance",
      "ข้อมูลบอท": "Bot Information",
      "Code แนะนำ": "Refer Code",
      "ใช้สัญญาณการเทรดจากเว็บไซต์ TradingView": "Use trading signals from the TradingView website",
      "ใช้สัญญาณการเทรดจาก Technical Trader": "Use trading signals from Technical Trader",
      "สุ่มเทรดว่าจะ BUY หรือ SELL": "Random trade whether to BUY or SELL",
      "เทรด BUY เท่านั้น": "Long Only",
      "เทรด SELL เท่านั้น": "Short Only",
      "เทรดตามเทรนด์ปัจจุบัน": "Trade with the current trend",
      "เทรดสวนเทรนด์ปัจจุบัน": "Trade against the current trend",
      "เทรดสลับวัน วันคู่เทรด SELL วันคี่เทรด BUY": "Alternate day trading, even day trading, SELL trading, odd day trading, BUY",
      "เทรดสลับวัน วันคู่เทรด BUY วันคี่เทรด SELL": "Alternate day trading, even day trading, BUY, odd day trading, SELL trading",
      "สถานะบอท": "Bot UI",
      "ออเดอร์ที่เปิดอยู่": "Open Orders",
      "ออเดอร์ปัจจุบัน": "Current Orders",
      "ข้อความ Error": "Error Logs",
      "บอททั้งหมด": "All Bot",
      "จำนวนเงินทั้งหมด": "Total Balances",
    }
  },
  th: {
    translation: {
      "BUY": "ซื้อ",
      "Step 1 Set up trade": "ขั้นตอนที่ 1 ตั้งค่าการเทรด",
      "Settings": "การตั้งค่า",
      "Step 2 Choose a trading signal": "ขั้นตอนที่ 2 เลือกสัญญาณการเทรด",
      "Step 3 Other Options": "ขั้นตอนที่ 3 ตัวเลือกอื่นๆ",
      "Register": "สมัครสมาชิก",
      "Login": "เข้าสู่ระบบ",
      "Credit": "เครดิต",
      "Top Up Credit": "เติมเครดิต",
      "User Profile": "โปรไฟล์ผู้ใช้",
      "Logout": "ลงชื่อออก",
      "User Management": "จัดการผู้ใช้",
      "User Database": "ฐานข้อมูลผู้ใช้",
      "Administrator": "แอดมิน",
      "Dashboard": "แดชบอร์ด",
      "Shopping Cart": "ตะกร้าสินค้า",
      "BUY Product": "ซื้อสินค้า",
      "Total Pieces": "จำนวนทั้งหมด",
      "Total Credit": "จำนวนเครดิตที่ใช้",
      "Your Credit": "เครดิตของคุณ",
      "Total PNL": "PNL ทั้งหมด",
      "Profit": "กำไร",
      "Loss": "ขาดทุน",
      "Credit History": "ประวัติการใช้เครดิต",
      "PNL Overview": "PNL ภาพรวม",
      "USDT Payment": "เติมเครดิตผ่าน USDT Payment",
      "Trade Settings": "ตั้งค่าการเทรด",
      "Bot Orders Settings": "ตั้งค่าบอทออเดอร์",
      "Bot": "บอท",
      "Choose a bot": "เลือกบอท",
      "Amount (USDT)": "จำนวน (USDT)",
      "Add Order": "เพิ่มออเดอร์",
      "Remove Order": "ลบออเดอร์",
      "Change Name": "เปลี่ยนชื่อ",
      "Order Number": "ลำดับออเดอร์",
      "Take Profit": "ตั้งกำไร (Take Profit)",
      "Stop Loss": "ตั้งหยุดขาดทุน (Stop Loss)",
      "Symbol": "คู่เทรด",
      "Signal": "สัญญาณ",
      "Update Order": "อัพเดทข้อมูลออเดอร์",
      "First Order Delay": "ระยะเวลาเปิดไม้แรก",
      "Order Delay Between": "ระยะเวลาเปิดไม้ต่อไป",
      "Long No Stop Loss": "ถ้า Long ไม่ตั้ง Stop Loss",
      "Short No Stop Loss": "ถ้า Short ไม่ตั้ง Stop Loss",
      "Update Trade Settings": "อัพเดทการตั้งค่าเทรด",
      "Start Trade": "เริ่มเทรด",
      "Stop Trade": "หยุดเทรด",
      "Top UP Credit Rate via ETH Payment": "เรทการเติมเครดิตผ่าน ETH Payment",
      "Select Bot": "เลือกบอท",
      "ETH Credit Payment": "เติมเครดิตผ่าน ETH Payment",
      "Your Credit": "เครดิตปัจจุบันของคุณ",
      "Last Login": "ล็อคอินล่าสุด",
      "Language": "ภาษา",
      "Email": "อีเมล",
      "Password": "รหัสผ่าน",
      "Cart": "ตะกร้า",
      "Profile": "โปรไฟล์",
      "Login History": "ประวัติการเข้าใช้งาน",
      "Balance": "คงเหลือ",
      "Bot Information": "ข้อมูลบอท",
      "Refer Code": "Code แนะนำ",
      "Use trading signals from the TradingView website": "ใช้สัญญาณการเทรดจากเว็บไซต์ TradingView",
      "Use trading signals from Technical Trader": "ใช้สัญญาณการเทรดจาก Technical Trader",
      "Random trade whether to BUY or SELL": "สุ่มเทรดว่าจะ BUY หรือ SELL",
      "Long Only": "เทรด BUY เท่านั้น",
      "Short Only": "เทรด SELL เท่านั้น",
      "Trade with the current trend": "เทรดตามเทรนด์ปัจจุบัน",
      "Trade against the current trend": "เทรดสวนเทรนด์ปัจจุบัน",
      "Alternate day trading, even day trading, SELL trading, odd day trading, BUY": "เทรดสลับวัน วันคู่เทรด SELL วันคี่เทรด BUY",
      "Alternate day trading, even day trading, BUY, odd day trading, SELL trading": "เทรดสลับวัน วันคู่เทรด BUY วันคี่เทรด SELL",
      "Bot UI": "สถานะบอท",
      "Open Orders": "ออเดอร์ที่เปิดอยู่",
      "Current Orders": "ออเดอร์ปัจจุบัน",
      "Error Logs": "ข้อความ Error",
      "All Bot": "บอททั้งหมด",
      "Total Balances": "จำนวนเงินทั้งหมด",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;