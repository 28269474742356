import React from 'react'
import { useContext } from 'react';
import Button from '@mui/material/Button';

import { collection, addDoc, getDoc, getDocs, doc, setDoc, updateDoc, increment, arrayUnion, arrayRemove } from "firebase/firestore"; 
import { db } from '../firebase';
import { AuthContext } from '../contexts/AuthContext';

const Test = () => {

    const { currentUser } = useContext(AuthContext)
    const uid = currentUser.uid
    const email = currentUser.email

    const addData = doc(db, "Users", email, email, "bot")
    const updateData = doc(db, "Users", email)
    const dataBase = doc(db, "Users", email)

    const timeData = Date().toLocaleString()
    console.log(timeData)

    // Add a document
    async function addDocument() {
        await setDoc(addData, {
            uid: uid,
            email: email,
            credit: '',
            expiration_date: '',
            last_login_date: '',
            bot: [{
                number: 1,
                product: 'binancefutures',
                api_key: '',
                secret_key: '',
                amount: 10,
                takeProfit: 4,
                stopLoss: 4,
                leverage: 5,
                orders: 5,
                orders_list: [{
                    no: 1,
                    amount: 100,
                    take_profit: 4.0,
                    stop_loss: 4.0,
                    symbol: "BTCUSDT", 
                    signal: "Long No Stop Loss"
                }],
                symbol: 'BTCUSDT',
                limitOrders: 1,
                currentOrder: 1,
                signal: 'Technical Trader',
                newSettings: true,
                longNoStopLoss: false,
                firstOrderDelay: 10,
                orderDelayBetween: 10
            }]
        })
    }

    async function addNewUser() {
        await setDoc(dataBase, {
            credit: 0,
            credit_history: [],
            email: email,
            expiration_date: '',
            last_login_date: '',
            uid: uid,
            trade_history: [],
            trade_order: [],
            pnl: {
                profit: 400,
                loss: 200,
                total: 200
            }
        })
    }

    // Update a document
    async function updateDocument() {
        await updateDoc(updateData, {
            bot: [{
                number: 2,
                product: 'binancefutures',
                api_key: '',
                secret_key: '',
                amount: 10,
                takeProfit: 4,
                stopLoss: 4,
                leverage: 5,
                orders: 5,
                symbol: 'BTCUSDT',
                limitOrders: 1,
                currentOrder: 1,
                signal: 'Technical Trader',
                newSettings: true,
                longNoStopLoss: false,
                firstOrderDelay: 10,
                orderDelayBetween: 10
            }]
        })
    }

    async function updateDocument2() {
        await updateDoc(updateData, {
            bot: arrayUnion({
                number: 1,
                product: 'binancefutures',
                api_key: '',
                secret_key: '',
                amount: 10000,
                takeProfit: 4,
                stopLoss: 4,
                leverage: 5,
                orders: 5,
                symbol: 'BTCUSDT',
                limitOrders: 1,
                currentOrder: 1,
                signal: 'Technical Trader',
                newSettings: true,
                longNoStopLoss: false,
                firstOrderDelay: 10,
                orderDelayBetween: 10
            })
        })
    }

    // Update elements in an array
    async function updateArrayDocument(num) {
        
        await updateDoc(updateData, {
            bot: arrayUnion({
                number: num,
                product: 'binancefutures',
                api_key: '',
                secret_key: '',
                amount: 10,
                takeProfit: 4,
                stopLoss: 4,
                leverage: 5,
                orders: 5,
                symbol: 'BTCUSDT',
                limitOrders: 1,
                currentOrder: 1,
                signal: 'Technical Trader',
                newSettings: true,
                longNoStopLoss: false,
                firstOrderDelay: 10,
                orderDelayBetween: 10
            })
        })
    }

    function addBot() {
        const numrows = 5
        for (let i = 1; i < numrows; i++) {
            updateArrayDocument(i)
        }
    }   

    // Increment a numeric value: increment()
    async function numericDocument() {
        await updateDoc(updateData, {
            name: increment(40)
        })
    }

    // Get a document
    async function getDocument() {
        const docSnap = await getDoc(updateData)

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            const botData = docSnap.data()
            const botData2 = botData.bot
            console.log(botData2)
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }

    // Get Bot Data
    async function getBotData() {
        const querySnapshot = await getDocs(collection(db, "Users", email, 'Bot'));
        querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        });
    }

    return (
    <div className="flex m-3 flex-wrap justify-center gap-7 items-center">
        <Button variant="contained" color="success" onClick={addDocument}>Add a document</Button>
        <Button variant="contained" color="success" onClick={addNewUser}>Add New User</Button>
        <Button variant="contained" color="success" onClick={updateDocument}>Update a document</Button>
        <Button variant="contained" color="success" onClick={updateDocument2}>Test Add Credit</Button>
        <Button variant="contained" color="success" onClick={addBot}>Update elements in an array</Button>
        <Button variant="contained" color="success" onClick={numericDocument}>Numeric value</Button>
        <Button variant="contained" color="success" onClick={getDocument}>Get a document</Button>
        <Button variant="contained" color="success" onClick={getBotData}>Get Bot Data</Button>
    </div>
    )
}

export default Test