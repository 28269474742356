import React from 'react';
import { useState, useEffect } from 'react'
import { MdOutlineCancel } from 'react-icons/md';

import { Button2 } from '.';
import { userProfileData } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import avatar from '../data/futuresx.png';

import i18next, { i18n } from 'i18next';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

import { db } from '../firebase';
import { getDoc, doc } from "firebase/firestore"; 

const UserProfile = () => {
  const { currentColor } = useStateContext();

  const navitage = useNavigate()

  function logout() {
    localStorage.setItem("user", JSON.stringify(""));
    window.location.reload(false)
  }

  const [userData, setUserData] = useState()
  const [userId, setUserId] = useState('')
  const [admin, setAdmin] = useState(false)
  const [lastLogin, setLastLogin] = useState('')

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))
    getUserProfile(user)
    checkAdmin(user.email)
    getData(user.email)
  }, []);

  function getUserProfile(user){
    setUserData(user.email)
    setUserId(user.uid)
  }

  function checkAdmin(user) {
    const isAdmin = 'ftx.c1920@gmail.com'
    if (isAdmin == user) {
        setAdmin(true)
    } else {
        setAdmin(false)
    }
  }

  async function getData(e) {
    const dataBase = doc(db, "Users", e)
    const docSnap = await getDoc(dataBase)

    if (docSnap.exists()) {
        const getData = docSnap.data()

        // Last Login
        const getData2 = getData.last_login_date
        setLastLogin(getData2)

    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
  }

  function toUserMangement () {
    navitage('/usermanagement')
  }

  function toUserDatabase () {
    navitage('/userdatabase')
  }

  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-90">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">{i18next.t('User Profile')}</p>
        <Button2
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex items-center mt-6 border-color border-b-1 pb-3">
        <img
          className="rounded-full h-24 w-24"
          src={avatar}
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-sm dark:text-gray-200"> {userData} </p>

          {/* Admin*/}
          {admin &&
          <p className="text-gray-500 text-sm dark:text-gray-400">  {i18next.t('Administrator')}   </p>
          }
          
          <p className="text-gray-500 text-xs dark:text-gray-400"> {userId}  </p>

        </div>
      </div>
      <div className="mt-5">

        {/* Admin*/}
        {admin && <div>
        <Button variant="contained" color="success" onClick={toUserMangement}>{i18next.t('User Management')}</Button> <br /> <br />
        <Button variant="contained" color="success" onClick={toUserDatabase}>{i18next.t('User Database')}</Button> <br /> <br />
        </div>
        }
        <Button variant="outlined" color="error" onClick={logout}>{i18next.t('Logout')}</Button>
      </div>
    </div>

  );
};

export default UserProfile;
