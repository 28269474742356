import React from 'react';
import { useState, useEffect, useContext } from 'react'
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page } from '@syncfusion/ej2-react-grids';
import { Header } from '../components';
import { db } from '../firebase';
import { doc, setDoc, getDocs, collection } from "firebase/firestore";
import { AuthContext } from '../contexts/AuthContext';

const Employees = () => {
  const toolbarOptions = ['Search'];

  const editing = { allowDeleting: true, allowEditing: true };

  const { currentUser } = useContext(AuthContext)
  const uid = currentUser.uid
  const email = currentUser.email

  const [allData, setAllData] = useState([])

  useEffect(() => {
      getDocument()
  }, []);

  // Get a document
  async function getDocument() {
    let list = []
    
    try {
      const querySnapshot = await getDocs(collection(db, "Users"));
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        list.push({ id: doc.id, ...doc.data() })
      })

      setAllData(list)
    } catch(err) {
      console.log(err)
    }
  }

  console.log(allData)

  const employeesGrid = [
    { field: 'email',
      headerText: 'Email',
      width: '150',
      textAlign: 'Left',
    },
    { field: 'credit',
      headerText: 'Credit',
      width: '150',
      textAlign: 'Left',
    },
    { field: 'last_login_date',
      headerText: 'Last Login',
      width: '150',
      textAlign: 'Left',
    },
  ]
  
  const employeesData = [
    {
      Email: 'demo@gmail.com',
      Credit: 400,
      ExpirationDate: '12/12/2022',
      LastLogin: '12/11/2022'
    },
  ]

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="แอดมิน" title="ข้อมูล Users" />
      <GridComponent
        dataSource={allData}
        width="auto"
        allowPaging
        allowSorting
        pageSettings={{ pageCount: 5 }}
        editSettings={editing}
        toolbar={toolbarOptions}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {employeesGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Search, Page]} />

      </GridComponent>
    </div>
  );
};
export default Employees;
